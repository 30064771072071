@import "../fonts.css";

.kds_layout {
  height: fit-content;
}

.kds_width_main {
  height: fit-content;
}

.kds_layout_main {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0;
  height: fit-content !important;
  /* height: 100vh; */
  overflow: hidden !important;
  background-color: var(---almost_white) !important;
}

.kds_layout_nope {
  display: none;
}

.kds_width {
  display: flex;
  width: 100% !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
}

.kds_date {
  width: 280px;
}

.kds_three_dots {
  width: 32px;
  height: 32px;
}

.kds_each_tab {
  width: 100% !important;
  padding: 25px !important;
}

.each_card_order {
  width: 311px;
  height: 402px;
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
}

.kds_sel_num {
  width: 24px;
  height: 24px;
  background-color: var(---secondary);
  border-radius: 5px;
  color: var(---primary);
  margin-left: 10px;
}

.kds_unsel_num {
  width: 24px;
  height: 24px;
  background-color: var(---almost_white);
  border-radius: 5px;
  color: var(---dark_gray);
  margin-left: 10px;
}

.kds_container {
  display: flex;
  justify-content: center;
}

/* mobile */

@media only screen and (max-device-width: 599px) {
  .kds_layout_main {
    display: none;
    visibility: hidden;
  }

  .kds_date {
    width: 0px;
    visibility: hidden;
  }

  .kds_three_dots {
    width: 0px;
    height: 0px;
    visibility: hidden;
  }

  .kds_layout_nope {
    display: flex;
  }
}

/* Portrait tablet 600-770*/

@media only screen and (min-device-width: 600px) and (max-device-width: 769px) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  /* .kds_layout_main {
        display: none;
        visibility: hidden;
    } */

  .kds_date {
    width: 0px;
    visibility: hidden;
  }

  .kds_three_dots {
    width: 0px;
    height: 0px;
    visibility: hidden;
  }

  .kds_layout_nope {
    display: none;
  }
}
