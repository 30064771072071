@import "../fonts.css";

.modifier_top {
  display: flex;
  align-items: center;
}

.modifier_top_btn {
  background-color: rgb(226, 210, 63) !important;
  margin-left: auto !important;
  color: var(---white) !important;
  text-transform: none !important;
}

.modifier_body {
  display: flex;
}

/* add modifier */

.add_modifier_body {
  display: flex;
  flex-direction: column;
  width: 900px;
  background: var(---white);
  border-radius: 5px;
  padding: 15px 25px;
}

.add_modifier_body_top {
  display: flex;
  justify-content: space-between;
}

.mod_left {
  display: flex;
  flex-direction: column;
  width: 49%;
  height: fit-content;
}

.mod_input {
  background: var(---white);
  border-radius: 12px;
  border: none;
  outline: none;
  padding-left: 8px;
  height: 40px;
  border: 1px solid var(---dark_gray);
}

.each_option {
  display: flex;
  flex-direction: column;
  background: var(---white);
  border: 1px solid var(---border_color);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 20px;
}

.plusCircle_option {
  display: flex;
  width: 35px;
  height: 35px;
  margin: 0px auto;
  color: var(---primary);
}

.each_option_add_price {
  width: fit-content !important;
  height: 35px !important;
  border: 1px solid var(---primary) !important;
  border-radius: 12px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---primary) !important;
  margin: 15px auto 0px auto !important;
  text-transform: none !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
