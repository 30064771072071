@import "../fonts.css";

.ant-radio-checked .ant-radio-inner {
  border-color: var(---primary) !important;
}

.ant-radio-inner::after {
  background-color: var(---primary) !important;
}

.emp_top {
  display: flex;
  flex-direction: column;
  height: auto !important;
  margin-bottom: 10px !important;
  text-transform: none !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  margin-left: 10px !important;
  color: var(---text) !important;
}

.emp_btn_sel {
  height: 40px !important;
  padding: 10px 40px !important;
  text-transform: none !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---primary) !important;
  background: var(---secondary) !important;
  border-radius: 25px !important;
}

.emp_btn_unsel {
  height: 40px !important;
  padding: 10px 40px !important;
  text-transform: none !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  border-radius: 25px !important;
  color: var(---text) !important;
}

/* designation */

.add_desig_body {
  display: flex;
  flex-direction: column;
  width: 700px;
  background-color: var(---white);
  padding: 25px;
  border-radius: 12px;
}

.adb_form {
  width: 24%;
}

.adesig_btn {
  border: 1px solid var(---primary) !important;
  background-color: var(---primary) !important;
  border-radius: 12px !important;
  color: var(---white) !important;
  text-transform: none !important;
  font-weight: 600 !important;
  margin-left: auto !important;
  padding: 6px 30px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  height: 45px !important;
  width: 250px !important;
}
