@import "../fonts.css";

.shippingMainDiv {
  display: flex;
}

.proceed__left {
  width: calc(100% - 360px - 20px);
  margin-right: 20px;
}

.shipping_layout_left {
  display: flex;
}

.shipping_layout_right {
  display: flex;
}

.proceed__left--stepper {
  width: 415px;
  margin: 0 auto;
}

.proceed__right {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: fit-content;
  background-color: var(---almost_white);
}

.order_note {
  width: 100%;
  height: 150px;
  background: var(---white);
  border: 1px solid var(---dark_gray);
  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(---text);
  padding: 6px 8px;
  outline: none;
}

.step__left {
  width: 48%;
}

.step__right {
  width: 48%;
  margin-left: 4%;
}

.shipping_eachCart_image {
  width: 80px !important;
  height: 80px !important;
  margin-right: 10px;
  border: 1px solid var(---gray);
  box-sizing: border-box;
  border-radius: 10.6667px;
}

/* stepper */

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(---primary) !important;
}

.ant-steps-item-container {
  display: flex !important;
  align-items: center !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(---text) !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: var(---primary) !important;
}

/* order summary */

.bottomDiv_ship {
  border-top: 1px solid var(---gray);
  padding: 15px;
  height: max-content;
}

.bottomDiv_ship--prep {
  display: flex;
  padding: 16px;
  background-color: var(---primary);
}

.bottomDiv_ship_oneOrder {
  border-top: 1px solid var(---gray);
  height: max-content;
}

.couponInput_ship {
  height: calc(1.5em + 0.75rem + 2px);
  border: 1px solid var(---dark_gray);
  padding: 0.375rem 0.75rem;
  border-radius: 12px;
  outline: none;
}

.couponBtn_ship {
  width: 33% !important;
  font-weight: bold !important;
  margin-left: 2% !important;
  color: var(---white) !important;
  background: var(---primary) !important;
  border-radius: 12px !important;
  text-transform: none !important;
}

/* delivery div */

.add_new_address {
  width: 100% !important;
  border: 1px solid var(---link) !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  line-height: 27px !important;
  color: var(---link) !important;
  text-transform: none !important;
}

.proceed_btn {
  width: 100% !important;
  background: var(---primary) !important;
  border-radius: 12px !important;
  color: var(---white) !important;
  text-transform: none !important;
  font-weight: 700 !important;
  margin-top: 20px !important;
  outline: none !important;
  height: 40px !important;
}

.eachUserAddress {
  font-size: 16px;
  line-height: 24px;
  border: 1px solid var(---gray);
  box-sizing: border-box;
  border-radius: 5px;
  background: var(---white);
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px 20px;
}

.eachUserAddress_Sel {
  font-size: 16px;
  line-height: 24px;
  background: var(---gray);
  border: 1px solid var(---link);
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px 20px;
}

.eachUserAdd_edit {
  color: var(---link) !important;
  background-color: transparent !important;
  border: none !important;
}

.eachUserAdd_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  text-transform: uppercase;
}

.ant-steps-item-active {
  color: var(---primary) !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: var(---primary) !important;
}

/* review */

/* sidebar */

.addside_input {
  width: 100%;
  height: 45px;
  border: 1px solid var(---dark_gray);
  box-sizing: border-box;
  border-radius: 5px;
  background: var(---white);
  outline: none;
  padding-left: 8px;
}

/* first */

.proceed_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-top: 40px;
  margin-bottom: 30px;
}

/* right */

.proceed_order_avatar {
  width: 90px;
  height: 90px;
  margin-right: 5px;
  border: 1px solid var(---gray);
  box-sizing: border-box;
}

.proceed_order_namePrice {
  display: flex;
  overflow: hidden;
  justify-content: center;
  margin-left: auto;
  margin-bottom: 10px;
}

.proceed_order_name {
  width: 300px;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.proceed_order_price {
  width: 60px;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  color: var(---primary);
  margin-left: auto;
}

.proceed_divider {
  width: 100%;
  height: 2px;
  margin: 10px 0px;
  background-color: var(---gray);
}

.proceed_subtotal {
  font-size: 14px;
  line-height: 21px;
}

.proceed_subtotal_cost {
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  color: var(---dark_gray);
}

.proceed_total {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.proceed_total_cost {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: var(---primary);
}

/* payment method */

.cod_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(---dark_gray);
}

/* thankyou page */

.great_text {
  text-align: center;
}

.back_home_btn {
  border: 1px solid var(---primary) !important;
  border-radius: 12px !important;
  color: var(---primary) !important;
  margin-right: 20px !important;
  padding: 8px 30px !important;
}

.track_order_btn {
  background: var(---primary) !important;
  border-radius: 12px !important;
  color: var(---white) !important;
  padding: 8px 30px !important;
}

/* mobile */

@media only screen and (max-width: 600px) {
  .shippingMainDiv {
    flex-direction: column;
  }

  .proceed__left {
    width: 100%;
    margin-right: 0px;
  }

  .proceed__left--stepper {
    width: 100%;
    margin: 0 auto;
  }

  .shipping_layout_left {
    flex-direction: column-reverse;
  }

  .shipping_layout_right {
    flex-direction: column;
  }

  .step__left {
    width: 100%;
  }

  .step__right {
    width: 100%;
    margin-left: 0%;
  }

  .proceed__right {
    width: 100%;
  }
}

/* Portrait tablet 600-770

@media only screen and (min-device-width: 600px) and (max-device-width: 769px) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
    .shippingMainDiv {
        flex-direction: column;
    }

    .proceed__left {
        width: 100%;
        margin-right: 0px;
    }

    .proceed__left--stepper {
        width: 100%;
        margin: 0 auto;
    }

    .shipping_layout_left {
        flex-direction: column;
    }

    .step__left {
        width: 100%;
    }

    .step__right {
        width: 100%;
        margin-left: 0%;
    }

    .proceed__right {
        width: 100%;
        margin-top: 10px;
    }
} */

/* Portrait tablet*/

@media only screen and (min-device-width: 601px) and (max-device-width: 1199px) and (orientation: portrait) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .shippingMainDiv {
    flex-direction: column;
  }

  .proceed__left {
    width: 100%;
    margin-right: 0px;
  }

  .proceed__left--stepper {
    width: 100%;
    margin: 0 auto;
  }

  .shipping_layout_left {
    flex-direction: column;
  }

  .shipping_layout_right {
    flex-direction: column;
  }

  .step__left {
    width: 100%;
  }

  .step__right {
    width: 100%;
    margin-left: 0%;
  }

  .proceed__right {
    width: 100%;
    margin-top: 10px;
  }
}

/* Landscape tablet*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1199px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .shippingMainDiv {
    flex-direction: column;
  }

  .proceed__left {
    width: 100%;
    margin-right: 0px;
  }

  .proceed__left--stepper {
    width: 100%;
    margin: 0 auto;
  }

  .shipping_layout_left {
    flex-direction: column;
  }

  .shipping_layout_right {
    flex-direction: column;
  }

  .step__left {
    width: 100%;
  }

  .step__right {
    width: 100%;
    margin-left: 0%;
  }

  .proceed__right {
    width: 100%;
    margin-top: 10px;
  }
}
