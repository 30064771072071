@import "../fonts.css";

.sidenavCartMain {
  display: flex !important;
  flex-direction: column !important;
  width: 410px !important;
  /* height: calc(100vh - 290px) !important; */
  overflow-x: hidden !important;
  scrollbar-width: thin !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
}

.topDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.topDivClear {
  font-size: 16px !important;
  line-height: 27px !important;
  color: var(---link) !important;
  text-transform: none !important;
}

/* list */

.eachCart_div {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  padding: 5px 15px;
}

.eachCart_div_oneOrder {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}

.eachCart_name {
  font-weight: 600;
  color: var(---text);
  text-align: justify;
  text-justify: inter-word;
}

.eachCart_image {
  width: 90px !important;
  height: 90px !important;
  margin-right: 10px;
  border: 1px solid var(---almost_white);
  box-sizing: border-box;
  border-radius: 5.625px;
}

.eachCart_price {
  color: var(---text);
  margin-top: auto;
}

/* bottom */

.bottomDiv {
  position: fixed;
  border-top: 1px solid var(---gray);
  padding: 15px;
  /* height: 275px; */
  height: auto;
  bottom: 0;
  right: 0;
  width: 410px;
  margin-top: auto !important;
  background-color: #fff !important;
}

.subTotalDiv {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
}

.subTotalAmt {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: var(---text);
}

.infoText {
  display: flex;
  color: var(---dark_gray);
}

.proceedBtn {
  width: 100% !important;
  height: 50px !important;
  font-weight: bold !important;
  text-transform: none !important;
  color: var(---white) !important;
  background: var(---primary) !important;
  border-radius: 12px !important;
  padding: 0.5rem !important;
}

#td {
  text-align: center;
  vertical-align: middle;
}

.empty_cart_img {
  width: 230px;
  height: 206px;
}

.empty_cart_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(---dark_gray);
}

.empty_cart_text {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(---dark_gray);
}

.mod_icon {
  width: 15px;
  height: 15px;
}

.cartQuanSideSke {
  width: 126px !important;
  height: 40px !important;
}

.cartQuanSideSkeQuanMy {
  width: 126px !important;
  height: 40px !important;
}

.mySKel_price {
  width: 80px !important;
  height: 23px !important;
}

.mySKel_sub {
  width: 100px !important;
  height: 23px !important;
}

.div-disabled {
  pointer-events: none;
  opacity: 0.4;
}

/* mobile */

@media only screen and (max-device-width: 599px) {
  .sidenavCartMain {
    width: 98vw !important;
  }

  .bottomDiv {
    width: 98%;
  }

  .eachCart_image {
    width: 70px !important;
    height: 70px !important;
  }

  .cartQuanSideSke {
    width: 96px !important;
    height: 30px !important;
  }
}

/* Portrait tablet 600-770*/

@media only screen and (min-device-width: 600px) and (max-device-width: 769px) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .sidenavCartMain {
    width: 400px !important;
  }

  .bottomDiv {
    width: 400px;
  }
}

/* Portrait tablet*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .sidenavCartMain {
    width: 400px !important;
  }

  .bottomDiv {
    width: 400px;
  }
}

/* Landscape tablet*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (min-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .sidenavCartMain {
    width: 400px !important;
  }

  .bottomDiv {
    width: 400px;
  }
}
