@import "../fonts.css";

.ad_dash_top {
  display: flex;
  width: 100%;
}

.ad_dash_top_av {
  width: 42px !important;
  height: 42px !important;
  margin-right: 15px !important;
  background-color: var(---almost_white) !important;
}

.ad_dash_top_value_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ad_dash_body {
  display: flex;
  flex-direction: column;
  /* width: 60%; */
  background-color: var(---white);
  border-radius: 12px;
  padding: 25px;
}

.ad_body_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.ad_body_view {
  font-family: "Outfit" !important;
  color: var(---link) !important;
  text-transform: none !important;
  font-weight: 500 !important;
}
