@import "../fonts.css";

.filter_main {
  display: flex;
  height: calc(100vh - 106px) !important;
  overflow: hidden !important;
}

.filter_main_home {
  overflow: hidden !important;
}

.fm_left {
  flex-direction: column !important;
  width: 280px !important;
  height: fit-content !important;
  max-height: calc(100% - 10px) !important;
  border: 1px solid var(---gray) !important;
  border-radius: 12px !important;
  padding: 15px !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  scrollbar-width: none !important;
}

.fm_right {
  display: flex;
  flex-direction: column;
  width: calc(100% - 320px);
  margin-left: 40px;
  padding: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

/* left */

.clear_btn {
  color: var(---link) !important;
  text-transform: none !important;
}

.cb_main {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
}

.cb_each {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
}

.fm_left_price {
  width: 100px;
  height: 37px;
  background: var(---white);
  border: 1px solid var(---dark_gray);
  border-radius: 5px;
  outline: none;
  padding: 6px 8px;
}

.ant-checkbox-group-item {
  margin-top: 5px;
  margin-bottom: 3px;
}

/* right */

.fm_right_sort_div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.fm_right_sort_btn {
  width: 160px !important;
  background: var(---white) !important;
  border: 1px solid var(---gray) !important;
  border-radius: 5px !important;
  text-transform: none !important;
  margin-left: 10px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(---text) !important;
  justify-content: left !important;
}

.cb_right_title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(---text);
}

.hp_each_popular_image_combo_filter {
  width: 100% !important;
  height: 200px !important;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.ml_mobile_last_filter {
  width: 40px !important;
  height: 40px !important;
  display: none !important;
  justify-content: center !important;
  align-items: center !important;
}

/* media query */

/* mobile */

@media only screen and (max-width: 600px) {
  .ml_mobile_last_filter {
    display: flex !important;
  }

  .filter_main {
    height: fit-content !important;
    overflow: scroll !important;
  }

  .fm_left {
    display: none;
    width: 0 !important;
  }

  .fm_right {
    width: 100%;
    margin-left: 0px;
    padding: 0;
    overflow: hidden;
    height: auto;
  }

  .hp_each_popular_image_combo_filter {
    width: 100% !important;
    height: 120px !important;
    margin-bottom: 0.6rem;
  }
}

/* Portrait tablet 600-770*/

@media only screen and (min-device-width: 600px) and (max-device-width: 769px) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .filter_main {
    height: fit-content !important;
    overflow: scroll !important;
  }

  .fm_left {
    display: none;
    width: 0 !important;
  }

  .fm_right {
    width: 100%;
    margin-left: 0px;
    padding: 0;
    overflow: hidden;
    height: auto;
  }

  .hp_each_popular_image_combo_filter {
    width: 100% !important;
    height: 200px !important;
  }
}


/* Portrait tablet*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .fm_left {
    width: 200px !important;
  }
  .fm_right {
    width: calc(100% - 220px);
    margin-left: 10px;
  }
}