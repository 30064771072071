@import "../fonts.css";

.landingPage {
  position: relative;
  height: 100vh;
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(---text);
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.landingFirst {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(---secondary);
  overflow: hidden;
}

.landingSecond {
  position: relative;
  width: 100% !important;
  padding: 35px;
}

.landingThird {
  display: flex;
  position: relative;
  width: 100% !important;
  padding: 35px;
  background-color: var(---almost_white);
  padding-bottom: 0px;
}

/* spin */
.landing_spin {
  display: flex;
  margin-top: auto;
  margin-left: auto;
  -webkit-animation-name: spinner;
  animation-name: spinner;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.imgHere {
  position: absolute;
  width: 100vh;
  height: 100vh;
  margin-top: -35vh;
  margin-left: -30vh;
}

@-webkit-keyframes spinner {
  83.33% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  5% {
    -webkit-transform: rotate(186deg);
    transform: rotate(186deg);
  }

  20% {
    -webkit-transform: rotate(190deg);
    transform: rotate(190deg);
  }

  30% {
    -webkit-transform: rotate(190deg);
    transform: rotate(190deg);
  }

  40% {
    -webkit-transform: rotate(190deg);
    transform: rotate(190deg);
  }

  50% {
    -webkit-transform: rotate(190deg);
    transform: rotate(190deg);
  }

  60% {
    -webkit-transform: rotate(190deg);
    transform: rotate(190deg);
  }

  70% {
    -webkit-transform: rotate(190deg);
    transform: rotate(190deg);
  }

  80% {
    -webkit-transform: rotate(190deg);
    transform: rotate(190deg);
  }

  85% {
    -webkit-transform: rotate(190deg);
    transform: rotate(190deg);
  }

  90% {
    -webkit-transform: rotate(190deg);
    transform: rotate(190deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

/* container */

@-webkit-keyframes imgFade {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes imgFade {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes imgFade {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes imgFade {
  0% {
    opacity: 1;
  }

  5% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  32% {
    opacity: 1;
  }

  34% {
    opacity: 0.5;
  }

  35% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  85% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

#container img {
  -webkit-animation-name: imgFade;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 18s;

  -moz-animation-name: imgFade;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 18s;

  -o-animation-name: imgFade;
  -o-animation-timing-function: ease-in-out;
  -o-animation-iteration-count: infinite;
  -o-animation-duration: 18s;

  animation-name: imgFade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 18s;
}

#container img:nth-of-type(1) {
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  animation-delay: 6s;
}

#container img:nth-of-type(2) {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

#container img:nth-of-type(3) {
  -webkit-animation-delay: -6s;
  -moz-animation-delay: -6s;
  -o-animation-delay: -6s;
  animation-delay: -6s;
}

/* top */

.firstTop {
  display: flex;
  height: 50px;
  width: calc(100vw -100px);
  margin-left: 100px;
  margin-top: 20px;
  align-items: center;
  padding: 35px;
}

.nova_title_logo {
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: var(---primary);
  cursor: pointer;
}

.nova_title_logo_img {
  width: 110px;
}

.nova_title_logo_white {
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: var(---white);
}

.firstTop_login {
  display: block !important;
  border: 1px solid var(---primary) !important;
  border-radius: 12px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---primary) !important;
  margin-left: auto !important;
  text-transform: none !important;
  padding: 4px 30px !important;
  height: 45px !important;
}

.firstTop_login_mobile {
  display: none !important;
}

.firstTop_signup {
  border-radius: 12px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---white) !important;
  background-color: var(---primary) !important;
  margin-left: 10px !important;
  margin-right: 15px !important;
  text-transform: none !important;
  padding: 4px 30px !important;
  height: 45px !important;
}

.firstBody {
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin-left: 100px;
  margin-top: auto;
  padding: 35px;
}

.firstBody_search {
  width: 500px !important;
  height: 55px !important;
}

.ant-select-selection-item,
.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.ant-select {
  outline: none !important;
}

.hungry_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 90px;
  color: var(---primary);
}

.find_food_div {
  display: flex;
  margin-top: 20px;
  z-index: 9999;
}

.find_food_btn {
  width: 200px !important;
  height: 55px !important;
  border-radius: 12px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---white) !important;
  background-color: var(---primary) !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  text-transform: none !important;
  padding: 4px 30px !important;
}

.landing_location_option_label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(---text);
}

.landing_location_option {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(---dark_gray);
}

/* second */

.second_cardTop {
  width: 100% !important;
  height: 200px !important;
  margin-bottom: 20px !important;
}

.second_card_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.text_justify {
  text-align: justify;
  text-justify: inter-word;
}

/* third */

.third_left {
  display: flex;
  flex-direction: column;
  width: calc(100% - 600px);
}

.third_left_title {
  font-weight: 900;
  font-size: 60px;
  line-height: 90px;
  color: var(---primary);
}

.third_right {
  width: 600px;
}

.third_right_image {
  height: 350px;
}

/*  media query */

/* mobile */

@media only screen and (max-width: 600px) {
  .firstTop {
    width: calc(100vw -100px);
    margin-left: 0px;
    margin-top: 10px;
    padding: 10px;
  }

  .firstTop_login {
    display: none !important;
  }

  .firstTop_login_mobile {
    display: block !important;
    border-radius: 12px !important;
    color: var(---white) !important;
    background-color: var(---primary) !important;
    margin-left: auto !important;
    text-transform: none !important;
    padding: 0px !important;
    height: 45px !important;
  }

  .firstBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    padding: 15px;
    padding-top: 50px;
  }

  .find_food_div {
    flex-direction: column;
  }

  .firstBody_search {
    width: 100% !important;
  }

  .find_food_btn {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 10px !important;
  }

  /* spin */
  .landing_spin {
    display: flex;
    margin-top: auto;
    margin-left: auto;
    -webkit-animation-name: spinner;
    animation-name: spinner;
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  .imgHere {
    position: absolute;
    width: 70vh;
    height: 70vh;
    margin-top: -35vh;
    margin-left: -30vh;
  }

  /* third */

  .landingThird {
    flex-direction: column;
  }

  .third_left {
    width: 100%;
  }

  .third_left_title {
    font-size: 40px;
    line-height: 60px;
  }

  .third_right {
    width: 100%;
  }

  .third_right_image {
    height: auto;
  }

  .app_store_img {
    width: 48%;
  }

  .nova_title_logo_img {
    width: 80px;
    height: auto;
  }
}

/* Portrait tablet 600-770*/

@media only screen and (min-device-width: 600px) and (max-device-width: 769px) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .firstTop {
    width: calc(100vw -100px);
    margin-left: 0px;
    margin-top: 10px;
    padding: 10px;
  }

  .firstTop_login {
    display: none !important;
  }

  .firstTop_login_mobile {
    display: block !important;
    border-radius: 12px !important;
    color: var(---white) !important;
    background-color: var(---primary) !important;
    margin-left: auto !important;
    text-transform: none !important;
    padding: 0px !important;
    height: 45px !important;
  }

  .firstBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    padding: 15px;
    padding-top: 50px;
  }

  .find_food_div {
    flex-direction: column;
  }

  .firstBody_search {
    width: 100% !important;
  }

  .find_food_btn {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 10px !important;
  }

  /* spin */
  .landing_spin {
    display: flex;
    margin-top: auto;
    margin-left: auto;
    -webkit-animation-name: spinner;
    animation-name: spinner;
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  .imgHere {
    position: absolute;
    width: 70vh;
    height: 70vh;
    margin-top: -35vh;
    margin-left: -30vh;
  }

  /* third */

  .landingThird {
    flex-direction: column;
  }

  .third_left {
    width: 100%;
  }

  .third_left_title {
    font-size: 40px;
    line-height: 60px;
  }

  .third_right {
    width: 100%;
  }

  .third_right_image {
    height: auto;
  }

  .app_store_img {
    width: 48%;
  }

  .nova_title_logo_img {
    width: 80px;
    height: auto;
  }
}

/* Portrait tablet*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .firstTop {
    width: calc(100vw -100px);
    margin-left: 0px;
    margin-top: 10px;
    padding: 10px;
  }

  .firstTop_login {
    display: none !important;
  }

  .firstTop_login_mobile {
    display: block !important;
    border-radius: 12px !important;
    color: var(---white) !important;
    background-color: var(---primary) !important;
    margin-left: auto !important;
    text-transform: none !important;
    padding: 0px !important;
    height: 45px !important;
  }

  .firstBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-top: auto;
    margin-bottom: 300px;
    padding: 15px;
    padding-top: 50px;
  }

  .hungry_text {
    margin: 0 auto;
  }

  .hungry_text_sub {
    margin: 0 auto;
  }

  .find_food_div {
    flex-direction: column;
  }

  .firstBody_search {
    width: 50% !important;
    margin: 0 auto;
  }

  .find_food_btn {
    width: 50% !important;
    margin: 0 auto !important;
    margin-top: 10px !important;
  }

  /* spin */
  .landing_spin {
    display: flex;
    margin-top: auto;
    margin-left: auto;
    -webkit-animation-name: spinner;
    animation-name: spinner;
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  .imgHere {
    position: absolute;
    width: 70vh;
    height: 70vh;
    margin-top: -35vh;
    margin-left: -30vh;
  }

  /* third */

  .landingThird {
    flex-direction: column;
  }

  .third_left {
    width: 100%;
  }

  .third_left_title {
    font-size: 40px;
    line-height: 60px;
  }

  .third_right {
    width: 100%;
  }

  .third_right_image {
    height: auto;
  }

  .app_store_img {
    width: 48%;
  }
}
