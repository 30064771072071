.banner_lg{
    width: 1210px;
    height: 267px;
}

/* Portrait tablet*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
    .banner_lg{
        width: 100vw;
        height: 200px;
    }
}