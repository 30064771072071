.loginCartModal {
  position: absolute;
  width: 462px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-color: #fff;
  box-shadow: 24;
  max-height: 95vh;
  border-radius: 12px;
  overflow: hidden;
}

.flag_img {
  width: 32px;
  height: 32px;
}

/* mobile */

@media only screen and (max-width: 600px) {
  .loginCartModal {
    width: 95%;
  }

  .flag_img {
    width: 20px;
    height: 20px;
  }
}
