@import "../fonts.css";

.cart_table {
  display: flex;
  margin-bottom: 20px;
}

.cart_table__left {
  width: 70%;
}

.cart_table__right {
  width: 29%;
  margin-left: 1%;
  padding: 16px;
  height: fit-content;
}

.cart_promo {
  display: flex;
}

.cart_promo__input {
  width: 300px;
  outline: none;
  background: var(---white);
  border-radius: 5px;
  height: 50px;
  padding: 0 8px;
  margin-right: 20px;
}

.mayAlsoLike_mb_div {
  display: none;
}

.mayAlsoLike_dis {
  width: fit-content;
  background: #32bf52;
  border-radius: 12px;
  margin: 10px;
  padding: 4px 8px;
  color: #ffffff;
}

.eachitem_brand {
  width: fit-content;
  max-width: 90%;
  border-radius: 12px;
  margin: 10px;
  margin-top: auto;
  align-items: center;
  padding: 0;
  height: 31px;
  padding: 0 6px;
  background-color: var(---white);
  border-radius: 5px;
}

.eachitem_brand_abs {
  position: absolute;
  top: 10px;
}

.eachitem_brandname {
  white-space: nowrap;
  /* width: 50px; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.mayAlsoLike_dis_filter {
  width: fit-content;
  background: #32bf52;
  border-radius: 12px;
  margin: 10px;
  padding: 4px 8px;
  color: #ffffff;
}

.ad_dash_top_cart {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.mycart_mobile {
  display: none;
}

.fixedElement {
  position: fixed;
  display: none;
  background: #fff;
  bottom: 0;
  left: 0;
  /* width: 100% !important; */
  /*  */
}

/* media query */

/* mobile */

@media only screen and (max-width: 600px) {
  .cart_table {
    display: none;
  }

  .ad_dash_top_cart {
    display: none;
  }

  .mayAlsoLike_mb_div {
    display: block;
  }

  .mycart_large {
    display: none;
  }

  .mayAlsoLike_mb {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 100% !important;
    margin-right: 15px;
    padding: 0.5em;
  }

  .mayAlsoLike_mb_each {
    width: 100% !important;
    height: 150px !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    margin-bottom: 0.6rem;
  }

  .mycart_mobile {
    display: block;
  }

  .fixedElement {
    display: block;
    width: 90%;
    left: 5%;
    padding: 10px 0;
  }

  .cart_promo__input {
    width: 80%;
  }
}

/* Portrait tablet 600-770*/

@media only screen and (min-device-width: 600px) and (max-device-width: 769px) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .cart_table {
    display: none;
  }

  .ad_dash_top_cart {
    display: none;
  }

  .mayAlsoLike_mb_div {
    display: block;
  }

  .mayAlsoLike_mb {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 100% !important;
    margin-right: 15px;
    padding: 0.5em;
  }

  .mayAlsoLike_mb_each {
    width: 100% !important;
    height: 200px !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
  }

  .mycart_mobile {
    display: block;
  }

  .fixedElement {
    display: block;
    width: 98%;
    left: 1%;
    padding: 0 20px;
  }
}

/* Portrait tablet*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .ad_dash_top_cart {
    display: none;
  }

  .mayAlsoLike_mb_div {
    display: block;
  }

  .mayAlsoLike_mb {
    display: flex;
    flex-direction: column;
    width: 282px;
    height: 100% !important;
    margin-right: 15px;
    padding: 0.5em;
  }

  .mayAlsoLike_mb_each {
    width: 100% !important;
    height: 200px !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
  }

  .cart_table {
    display: none;
  }

  .mycart_mobile {
    display: block;
  }

  .fixedElement {
    display: block;
    width: 98%;
    left: 1%;
    padding: 0 20px;
  }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1199px) and (orientation: portrait) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .cart_table {
    display: none;
  }

  .mycart_mobile {
    display: block;
  }

  .mycart_large {
    display: none;
  }

  .fixedElement {
    display: block;
    width: 98%;
    left: 1%;
    padding: 0 20px;
  }

  .ad_dash_top_cart {
    display: none;
  }

  .mayAlsoLike_mb_div {
    display: block;
  }
}

/* Landscape tablet*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1199px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .cart_table {
    display: none;
  }

  .mycart_mobile {
    display: block;
  }

  .mycart_large {
    display: none;
  }

  .fixedElement {
    display: block;
    width: 98%;
    left: 1%;
    padding: 0 20px;
  }

  .ad_dash_top_cart {
    display: none;
  }

  .mayAlsoLike_mb_div {
    display: block;
  }

  .mayAlsoLike_mb {
    display: flex;
    flex-direction: column;
    width: 282px;
    height: 100% !important;
    margin-right: 15px;
    padding: 0.5em;
  }

  .mayAlsoLike_mb_each {
    width: 100% !important;
    height: 200px !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
  }
}
