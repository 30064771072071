/* @import url(https://db.onlinewebfonts.com/c/335787aa6f57d71cabe8eb5dc89c6d6b?family=Blender+Pro+Book); */

@font-face {
  font-family: "Refinery 15 Bold";
  src: url("../../fonts/refinery-15-bold.ttf") format("truetype");
}

@font-face {
  font-family: "Blender Pro thin";
  src: url("../../fonts/BlenderPro-Thin.woff") format("woff2");
}

@font-face {
  font-family: "Blender Pro bold";
  src: url("../../fonts/BlenderPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Blender Pro medium";
  src: url("../../fonts/BlenderPro-Medium.ttf") format("truetype");
}

.csr_dashboard_container {
  background-color: #000000;
  width: 100%;
  height: calc(100vh - 4.65rem);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  overflow-x: scroll;
}

.csr_dashboard_table {
  width: 29.75rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1.5rem;
}

.csr_dashboard_table .search {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.csr_dashboard_table .search .add {
  width: 2.6rem;
  height: 2.5rem;
  background-color: #fcee0a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.csr_dashboard_table .search .add:hover {
  cursor: pointer;
}

.csr_dashboard_table .table {
  width: 29.75rem;
  height: 35.5rem;
  overflow: hidden;
  padding: 0px;
}

.crs_table_rows {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

.table > :not(caption) > * > * {
  padding: 0 !important; /* Set padding to 0 */
  margin: 0; /* Set margin to 0 */
}

.crs_table_rows img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.csr_dashboard_divider {
  width: 25px;
  height: 100%;
}

.csr_dashboard_divider img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.csr_dashboard_contents {
  width: 51rem;
  height: 100%;
}

.csr_layout_main {
  max-width: 180rem;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
  background-color: var(---black) !important;
  display: flex !important;
  flex-direction: column !important;
}

.csr_body {
  background-color: var(---black) !important;
  width: 100%;
  height: 200px;
}

.csr_layout_appbar {
  max-width: 180rem;
  position: static !important;
  margin: 0 auto;
  width: 100%;
  height: 4.65rem;
  background-color: var(---black) !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between;
}

.csr_layout_appbar_div {
  display: flex !important;
  min-width: 13rem !important;
  width: fit-content !important;
  height: 2.25rem !important;
  background-color: var(---dark) !important;
  margin-right: 3.1rem;
  overflow: hidden;
  /* justify-content: flex-end !important; */
}

.csr_header_logo {
  width: 15rem;
  height: 2.65rem;
  margin-left: 3.1rem;
}

.csr_header_logo .logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.csr_header_moto {
  width: 22.5rem;
  height: 2.37rem;
}

.csr_header_logo .moto {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.csr_user {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
}

.csr_user p {
  padding: 0 !important;
  margin: 0 !important;
  color: #ffffff;
  font-family: "Blender Pro thin";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.csr_create_div {
  display: flex;
  width: 51.6rem;
  background: var(---black);
  padding: 24px 24px 24px 4px;
  justify-content: space-between;
  color: var(---white);
  border-radius: 0px;
  font-family: "Blender Pro thin";
}

.csr_div_user_details {
  display: flex;
  width: 51.6rem;
  background: black;
  flex-direction: row;
  gap: 1.5rem;
  justify-content: space-between;
  color: white;
  border-radius: 0px;
  font-family: "Blender Pro thin";
}
.csr_div_user_details_child_first {
  width: 33.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.csr_div_user_details_child_second {
  height: fit-content;
  flex-direction: column;
  border: 1px solid #545356;
  padding: 1rem;
  overflow: hidden;
}

.csr_create_order_header {
  height: 2.3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  width: 51.6rem;
  background: var(---black);
  padding: 24px 24px 24px 4px;
  color: var(---white);
}

.csr_create_order_header_titles {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: white;
  font-family: "Refinery 15 Bold";
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: fit-content;
}

.csr_create_order_header .select {
  width: 10.25rem;
  height: 2.3rem;
  background-color: black;
  color: white;
  font-family: "Blender Pro thin";
  font-size: 1rem;
  line-height: 1.2rem;
  padding-left: 6px;
  border: 1px solid #545356;
}
.csr_create_order_header .select .select_option:hover {
  background-color: #545356 !important;
}
.csr_create_order_header .select:focus {
  outline: none;
}

.csr_text_area {
  width: 100%;
  height: 9.3rem;
  background: var(---black);
  border: 1px solid #545356;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  padding: 6px 8px;
  font-family: "Blender Pro thin";
  outline: none;
}

.csr_add_item {
  border: 1px solid var(---csr_yellow) !important;
  color: var(---csr_yellow) !important;
  text-transform: none !important;
  font-weight: 400 !important;
  height: 45px !important;
  font-size: 1rem;
  line-height: 1.1rem;
  font-family: "Blender Pro thin";
}

.csr_select {
  width: 100%;
  height: 2.3rem;
  background-color: black;
  color: white;
  font-family: "Blender Pro thin";
  font-size: 1rem;
  line-height: 1.2rem;
  padding-left: 6px;
  border: 1px solid #545356;
}

.cart_quan_div_csr {
  display: flex;
  width: fit-content;
  white-space: nowrap;
  border: 1px solid white;
  border-radius: 0px;
  height: 2rem;
  margin: auto 0;
}

.cq_minus_plus_csr {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 2rem !important;
  max-width: 2rem !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #00f0ff !important;
}

.cq_line_csr {
  border: 1px solid white;
}
.cq_text_csr {
  padding: 2px 2px !important;
  font-weight: 400 !important;
  font-size: 1.1rem !important;
  line-height: 21px !important;
  color: white !important;
  min-width: 2rem !important;
  max-width: 2rem !important;
}

.custom-text-field:focus {
  outline: none !important;
}

.adocr_cancel_csr {
  color: var(---csr_blue) !important;
  font-weight: 500 !important;
  padding: 6px 30px !important;
  font-size: 1.2rem !important;
  line-height: 1.35rem !important;
  height: 2.3rem !important;
  width: 5rem !important;
  text-transform: uppercase;
  font-family: "Blender Pro thin" !important;
}

.adocr_add_btn_csr {
  width: 10.1rem !important;
  height: 2.3rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.adocr_add_btn_csr:hover {
  cursor: pointer;
}
.adocr_add_btn_csr img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.adocr_right_items_div_csr {
  border: 1px solid #545356;
  padding: 10px;
}

.csr_little_texts_moderate {
  font-family: "Blender Pro thin";
  font-size: 1.12rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.35rem;
  color: #ffffff;
}
.csr_little_texts {
  font-family: "Blender Pro thin" !important;
  font-style: normal !important;
  font-weight: 400;
  line-height: 1.35rem !important;
}
.csr_little_texts_little {
  font-family: "Blender Pro thin";
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.35rem;
  color: #ffffff;
}

.eachCart_price_csr {
  color: white;
  margin-top: auto;
}

.spiceLevel_text_csr {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: red;
  text-transform: uppercase;
  margin: 20px auto;
  margin-top: 0px;
}

.half_right_btn_comp_csr {
  display: flex !important;
  width: 10.1rem !important;
  height: 2.3rem !important;
  margin: 15px 0 !important;
  margin-left: auto !important;
  justify-content: center !important;
  align-items: center !important;
}
.half_right_btn_comp_csr img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.half_right_btn_comp_csr:hover {
  cursor: pointer;
}

.notifi_each_csr {
  width: 100%;
  border: 1px solid #545356;
  border-radius: 0px;
  padding: 16px 12px;
  font-family: "Blender Pro thin";
}

.filter_button_csr {
  width: 5.1rem !important;
  height: 2.5rem !important;
  border-radius: 0px !important;
  background-color: black !important;
  border: 1px solid #00f0ff !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 6px !important;
  color: #00f0ff !important;
  font-family: "Blender Pro thin" !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.filter_button_csr div {
  height: 1.1rem !important;
  width: fit-content !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ov_right_csr {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(---black);
  overflow: hidden;
}

.ov_details {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  align-items: center !important;
  width: 13.75rem !important;
  height: 3.12rem !important;
  gap: 0.75rem !important;
  margin-bottom: 1.5rem !important;
}

.ov_img_csr {
  width: 3.12rem !important;
  height: 3.12rem !important;
}

.ovr_name_csr {
  color: #fff;
  font-family: "Blender Pro thin";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 !important;
}

.ovr_phone_csr {
  color: #fff;
  font-family: "Blender Pro thin";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 !important;
}

.ov_details_content {
  width: fit-content;
  height: 2.68rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.ov_user_details_text {
  color: #fff;
  font-family: "Blender Pro thin";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ovr_blue {
  color: #00f0ff;
  font-family: "Blender Pro thin";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ovr_note_csr {
  width: 100%;
  height: fit-content;
  background: var(---black);
  align-self: stretch;
  font-family: "Blender Pro thin";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ovr_note_csr .note {
  width: 100% !important;
  height: fit-content !important;
  color: white !important;
  word-wrap: break-word !important;
}

.del_assign_csr {
  width: 20rem;
  background: var(---black);
  border-radius: 0px;
  border: 1px solid #545356;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.del_assign_view_csr {
  font-family: "Blender Pro medium" !important;
  width: 20rem;
  background: var(---black);
  border-radius: 0px;
  border: 1px solid #545356;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.csr_button {
  border: 1px solid #8943ef;
  color: #8943ef;
  font-family: "Blender Pro thin";
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 8rem;
  margin-top: 1.5rem;
  padding: 5px 0px;
  height: 2rem;
  margin-left: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.csr_button:hover {
  cursor: pointer;
}

.csr_view_details_container {
  width: 52rem;
  height: fit-content !important;
}

.blacklist_button_csr {
  border: 1px solid red;
  padding: 0.3rem 1.5rem;
  color: red;
  font-weight: 600;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.OrderViewTableCsr{
  width: 33.5rem !important;
}

.table > :not(caption) > * > *{
  background-color: black !important;
}

.stat_btn_1{
  display: flex;
}

.stat_btn_2{
  display: none;
}

.ipadPort{
  display: none !important;
}

/* landscape iPad */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1025px) 
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1) 
  {
    .csr_view_details_container{
      width: 100%;
    }
    .csr_layout_appbar{
      position: fixed;
    }
    .csr_dashboard_contents {
      width: 490px;
      overflow: hidden;
      margin-left: auto;
      height: max-content !important;
    }
    .csr_create_div {
      flex-direction: column !important;
      width: 400px;
    }
    .csr_create_order_header {
      width: 400px;
      height: auto;
    }
    .tab_fixed_table{
      position: fixed;
      left: 24px;
      top: 55px;
    }
    .csr_dashboard_table{
      width: 100%;
    }
    .csr_dashboard_table .table {
      width: 475px;
    }
    .csr_dashboard_container{
      padding:  32px;
      overflow-y: auto !important;
    }
    .csr_dashboard_divider{
      margin-left: 500px;
    }
    .csr_create_order_header_titles {
      min-width: fit-content;
      margin-right: 10px;
    }
    .csr_create_order_header{
      padding: 24px 0;
    }
    .csr_div_user_details {
      width: 100%;
    }
    .csr_div_user_details_child_first {
      width: 100%;
    }   
    .OrderViewTableCsr{
      width: 100% !important;
    }
    .ov_details {
      width: 100% !important;
    }
    .stat_btn_1{
      display: none;
    }
    .stat_btn_2{
      display: flex;
    }
    .ipadPort{
      display: none !important;
    }
}

/* Targeting portrait orientation for an iPad */
@media only screen and (min-device-width: 768px) and (max-device-width: 834px) and (orientation: portrait) {
  /* Your CSS rules here */
  .csr_dashboard_divider {
    display: none !important;
  }
  .csr_dashboard_contents {
    display: none !important;
  }
  .csr_view_details_container{
    padding: 24px !important;
  }
  .stat_btn_1{
    display: flex;
    margin-left: 10px;
  }
  .stat_btn_2{
    display: none;
  }
  .change_status{
    margin-left: 10px;
  }
  .csr_view_details_container {
    width: 100%;
  }
  .user_deets_div_2{
    display: none;
  }
  .csr_create_order_header{
    width: 100%;
  }
  .csr_dashboard_container_2{
    background-color: #000000;
    width: 100%;
    height: calc(100vh - 4.65rem);
    display: flex;
    flex-direction: row;
    gap: 5px;
    overflow-x: scroll;
    flex-direction: column;
    padding: 24px;
  }
  .printNeo{
    width: 0;
  }
  .ipadPort{
    display: flex !important;
  }
  .csr_create_div {
    flex-direction: column !important;
    width: 400px;
  }
  .csr_create_div_2{
    width: 100% !important;
  }
  .csr_div_user_details{
    width: 100%;
  }
}