@import url("https://fonts.googleapis.com/css2?family=Suranna&family=Zen+Antique&display=swap");

.login {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
}

.login .items {
  background-color: #000000;
  width: 50%;
  height: 100%;
}

.items .img_car {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login .logo {
  height: 3.1rem;
  width: 289px;
}

.items_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login .heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  width: 18rem;
  height: 6.12rem;
  margin-top: 6.25rem;
}

.login .heading_text {
  color: #fff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  font-family: "Suranna", serif;
  font-family: "Zen Antique", serif;
}

.login .form {
  height: fit-content;
  margin-top: 3.25rem;
  width: 24.25rem;
  height: 20.75rem;
}

.form {
  background-color: #00000080;
  border: 1px solid #494550;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 24px;
  position: relative;
}

.form .text-wrapper {
  color: white;
  font-family: "Suranna", serif;
  font-family: "Zen Antique", serif;
  font-weight: 400;
  font-size: 1.5rem;
  width: 6rem;
}

.form .inputs {
  width: 21.25rem;
  height: 10.18rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inputs_submit_button {
  width: 10.18rem;
  height: 2.3rem;
}

.inputs_submit_button .button {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.inputs_submit_button .button:hover {
  cursor: pointer;
}
