@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap");
@import "../css/fonts.css";

.full_btn_comp {
  display: flex !important;
  width: 100% !important;
  color: var(---white) !important;
  margin: 15px 0 !important;
  height: 50px !important;
  background-color: var(---primary) !important;
  text-transform: none !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  border-radius: 12px !important;
}

.full_btn_comp_loading {
  height: 40px !important;
  color: var(---secondary) !important;
}

.btn_comp {
  display: flex !important;
  width: 200px !important;
  height: 50px !important;
  color: var(---white) !important;
  background-color: var(---primary) !important;
  font-weight: 600 !important;
  text-transform: none !important;
  border-radius: 12px !important;
}

.half_right_btn_comp {
  display: flex !important;
  width: 200px !important;
  height: 50px !important;
  color: var(---white) !important;
  background-color: var(---primary) !important;
  margin: 15px 0 !important;
  margin-left: auto !important;
  font-weight: 600 !important;
  text-transform: none !important;
  border-radius: 12px !important;
}

.category_top_add {
  height: 50px !important;
  margin-bottom: 10px !important;
  text-transform: none !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  margin-left: 10px !important;
  color: var(---text) !important;
}

.category_top {
  display: flex;
  flex-direction: column;
  height: 80px !important;
  margin-bottom: 10px !important;
  text-transform: none !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  margin-left: 10px !important;
  color: var(---text) !important;
}

.category_top_inventory {
  display: flex;
  flex-direction: column;
  height: 30px !important;
  margin-bottom: 20px !important;
  text-transform: none !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  margin-left: 10px !important;
  color: var(---text) !important;
}

.each_top_btn {
  width: fit-content !important;
  height: 40px !important;
  background-color: var(---primary) !important;
  margin-left: 40px !important;
  color: var(---white) !important;
  text-transform: none !important;
  border-radius: 12px !important;
  padding: 0 40px !important;
  font-family: "Poppins" !important;
  margin-top: 5px !important;
  margin-left: auto !important;
}

.errText {
  color: var(---error-dark) !important;
}

.add_category_input {
  display: flex !important;
  width: 100% !important;
  outline: none !important;
  background: var(---white) !important;
  border: 1px solid var(---dark_gray) !important;
  border-radius: 12px !important;
  height: 40px !important;
  padding: 0 8px !important;
}

.add_category_input_full {
  width: 100%;
  outline: none;
  background: var(---white);
  border: 1px solid var(---dark_gray);
  border-radius: 12px;
  height: 40px;
  padding: 0 8px;
}

.css-1s2u09g-control {
  border-radius: 12px !important;
  border-color: var(---dark_gray) !important;
  outline: none !important;
}

.css-1s2u09g-control:hover {
  border-color: var(---dark_gray) !important;
}

.css-1pahdxg-control {
  outline: 0 !important;
  box-shadow: 0 0 0 0px hsl(0, 0%, 80%) !important;
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 12px !important;
}

.ant-input {
  height: 100% !important;
}

.ant-input-group-addon {
  border: 0 !important;
  /* border-left: 1px solid var(---dark_gray) !important; */
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  outline: none !important;
}

.edit_delete_text {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: var(---text) !important;
  text-transform: none !important;
  justify-content: left !important;
}

/* select image */
.prodEachImageDiv {
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.prodEachImageInside {
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.prodEachImageCancel {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 1;
  right: 3px;
  top: 3px;
  color: var(---caution);
  border-radius: 50%;
  background-color: var(---gray);
}

.prodEachImage {
  width: 100%;
  height: 100%;
}

.addAnotherImgDiv {
  display: flex;
  width: 60px;
  height: 60px;
  border: 1px dashed #0C72DE;
  box-sizing: border-box;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.addAnotherImg {
  width: 70%;
  height: 70%;
  color: #0C72DE;
}

/* small */

.add_small_image_box {
  display: flex;
  background: var(---gray);
  border: 1px dashed var(---link);
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 90px !important;
  overflow: hidden !important;
}

.upload-btn-wrapper-small {
  position: relative;
  overflow: hidden;
  display: inline-block;
  background-color: var(---almost_white);
  width: 100%;
  height: 90px !important;
  display: flex;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.browseText_small {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: var(---text);
}

.browseTextBold_small {
  font-weight: bold;
  color: var(---link);
}

.upload-btn-wrapper-show-small {
  position: relative;
  overflow: hidden;
  display: inline-block;
  background-color: var(---almost_white);
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  border: 1px dashed var(---link);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
}

.upload-btn-wrapper-small input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-btn-wrapper-show-small input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.add_small_image_div {
  position: relative;
  width: 60px;
  height: 60px;
}

.add_small_image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.small_ImageCancel {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 1;
  right: 20px;
  top: 20px;
  color: var(---caution);
  border-radius: 50%;
  background-color: var(---gray);
}

/* react-select multi */
.css-1rhbuit-multiValue {
  padding: 2px 12px !important;
  border-radius: 12px !important;
  background-color: var(---primary) !important;
  color: var(---white) !important;
}

.css-12jo7m5 {
  color: var(---white) !important;
}

/* invalid page */

.invalid_div {
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.invalid_img {
  width: 426px;
  height: 370px;
}

.invalid_t1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px !important;
  color: var(---primary);
}

.invalid_t2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px !important;
  text-align: center;
  color: var(---black);
}

.invalid_btn {
  width: 180px !important;
  height: 48px !important;
  font-weight: 600 !important;
  color: var(---white) !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: none !important;
  background: var(---primary) !important;
  border-radius: 12px !important;
}

/* ant checkbox */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: red !important;
  border-color: red !important;
}

.ant-checkbox-checked::after {
  border: var(---primary) !important;
}

.ant-checkbox-group {
  display: flex !important;
  flex-direction: column !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(---primary) !important;
}

/* inventory */

.ant-spin-dot-item {
  background-color: var(---primary) !important;
}

.inventory_input {
  border: var(---white) 1px solid !important;
}

.inventory_input:focus {
  border: var(---dark_gray) 1px solid !important;
  border-radius: 12px !important;
}

.novaTextarea {
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  outline: none;
}

/* cart quanitity change */

.cart_quan_div {
  display: flex;
  width: fit-content;
  white-space: nowrap;
  border: 1px solid var(---gray);
  border-radius: 5px;
  height: 32px;
  margin: auto 0;
}

.cq_minus_plus {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 30px !important;
  max-width: 30px !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(---link) !important;
}

.cq_text {
  padding: 2px 2px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(---text) !important;
  min-width: 30px !important;
  max-width: 30px !important;
}

.cq_line {
  border: 0.69863px solid var(---gray);
}

.ant-modal-footer {
  display: flex !important;
}

.ant-modal-mask {
  background-color: var(---text) !important;
  opacity: 0.5 !important;
}

/* market order history */
.oh_btn {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---primary) !important;
  border: 1px solid var(---primary) !important;
  border-radius: 12px !important;
  text-transform: none !important;
  width: 48% !important;
  height: 45px !important;
}

.oh_status_btn {
  height: 25px !important;
  border: 1px solid !important;
  border-radius: 3px !important;
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
}

.oh_queue {
  border-color: var(---dark_gray) !important;
  color: var(---dark_gray) !important;
}

.oh_not_assigned {
  border-color: var(---purple) !important;
  color: var(---purple) !important;
}

.oh_cooking {
  border-color: var(---caution) !important;
  color: var(---caution) !important;
}

.oh_picked_up {
  border-color: var(---sky_blue) !important;
  color: var(---sky_blue) !important;
}

.oh_comp {
  border-color: var(---success) !important;
  color: var(---success) !important;
}

.oh_cancelled {
  border-color: var(---primary) !important;
  color: var(---primary) !important;
}

/* dialog */

.confirm_dialog {
  border-radius: 12px !important;
  padding: 200px !important;
}

.dialog_text {
  font-size: 24px !important;
  line-height: 36px !important;
  font-weight: 600 !important;
  color: var(---primary) !important;
}

/* kds */

.kds_menu_each {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: var(---text) !important;
  text-transform: none !important;
  justify-content: left !important;
}

/*  media query */

/* mobile */

@media only screen and (max-width: 600px) {
  /* invalid page */
  .invalid_div {
    width: auto;
    margin: 20px;
    overflow-y: auto;
  }

  .invalid_img {
    width: 95%;
    height: auto;
  }

  .invalid_t1 {
    text-align: center;
    font-size: 25px;
    line-height: 40px;
  }

  .invalid_btn {
    width: 100% !important;
  }

  /* common */
  .category_top_add {
    margin-bottom: 10px !important;
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .cart_quan_div {
    height: 30px;
  }

  .cq_minus_plus {
    min-width: 30px !important;
    max-width: 30px !important;
    height: 30px !important;
  }

  .cq_text {
    min-width: 30px !important;
    max-width: 30px !important;
    height: 30px !important;
  }
}

/* order-loader */
#order-loader-div {
  height: 50px;
  width: 50px;
  border: 3px solid #28a745; /* Equivalent to border-4 border-solid border-success */
  border-top-color: #f0f0f0; /* Equivalent to border-t-16 border-t-light_gray */
  border-radius: 50%; /* Equivalent to rounded-[50%] */
  animation: loader-animation 1s infinite; /* Animation */
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
