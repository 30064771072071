.underlineWho::before {
  content: "\a0\a0\a0\a0\a0\a0\a0\a0";
  display: block;
  position: absolute;
  text-decoration: underline;
  text-decoration-color: var(---primary);
  text-decoration-thickness: 6px;
  margin-top: 10px;
  overflow: hidden;
}

.ourValuesImg {
  width: 5em;
  height: 5em;
  align-self: center;
  align-content: center;
}

.whoweare-hauto {
  height: 100vh;
}

/* mobile */

@media only screen and (max-width: 600px) {
  .whoweare-hauto {
    height: auto;
  }
}
