@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import "../fonts.css";

.transparent_button {
  border-radius: 12px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  background-color: transparent !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  text-transform: none !important;
  padding: 4px 20px 4px 0px !important;
}

.admin_layout_main {
  width: 100vw;
  height: 100vh;
  font-family: "Poppins" !important;
  overflow: hidden !important;
  scrollbar-width: thin !important;
  background-color: var(---almost_white) !important;
  font-family: "Outfit" !important;
}

.admin_layout_appbar {
  left: 240px !important;
  background-color: var(---almost_white) !important;
  color: var(---black) !important;
}

.admin_drawer {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.admin_drawer_kds {
  display: flex !important;
  width: 90% !important;
  height: 48px !important;
  background-color: var(---primary) !important;
  color: var(---white) !important;
  text-transform: none !important;
  border-radius: 12px !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 20px auto !important;
  margin-top: auto !important;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  overflow-x: hidden !important;
  scrollbar-width: thin !important;
}

.nova_title_text {
  display: flex;
  margin: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: var(---primary);
}

.admin_body {
  position: absolute !important;
  width: calc(100% - 240px) !important;
  /* height: calc(100% - 64px) !important; */
  top: 64px !important;
  left: 240px !important;
  padding: 20px 30px !important;
  background-color: var(---almost_white) !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  scrollbar-width: thin !important;
}

.tss-1cdcmys-MUIDataTable-responsiveBase {
  scrollbar-width: thin !important;
  overflow-x: hidden !important;
}

/* ant table */

.ant-table-thead > tr > th {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(---text);
  background-color: var(---white) !important;
}

.ant-table {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  max-height: 70vh !important;
  overflow: scroll !important;
}

/* ant dropdwon */

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  padding: 5px 20px 0px 20px !important;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: transparent !important;
}

.ant-picker-clear > * {
  display: none !important;
}

.ant-picker-focused {
  box-shadow: none !important;
}

/* appbar large screen */

.admin_layout_appbar_div {
  display: flex !important;
  /* width: 100% !important; */
  width: calc(100vw - 320px) !important;
  background-color: var(---almost_white) !important;
  /* justify-content: flex-end !important; */
  align-items: center;
}

.avatar_name {
  font-family: "Poppins" !important;
  display: flex !important;
  font-weight: 600 !important;
  text-transform: none !important;
  color: var(---text) !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

/* notification */

.ad_notif_break {
  word-wrap: break-word;
}

.ad_no_notif {
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

/*  media query */

/* mobile */

@media only screen and (max-width: 600px) {
  .admin_body {
    width: 100% !important;
    /* height: calc(100% - 64px) !important; */
    top: 56px !important;
    left: 0px !important;
    padding: 20px 30px !important;
  }

  .admin_layout_appbar {
    left: 0px !important;
    background-color: var(---almost_white) !important;
  }
}

.shift_button {
  display: flex;
  height: 2rem;
  padding: 0.375rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.75rem;
  border: 1px solid var(--Text, #2b2b2b);
  text-align: center;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 1.5rem;
}
.shift_button:hover {
  cursor: pointer;
}
