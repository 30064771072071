@import "../fonts.css";

.myAcc {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(---text);
}

.myAccountMain {
  display: flex;
  width: 100%;
}

.ac_left {
  display: flex;
  flex-direction: column;
  width: 215px;
}

.ac_left_btn {
  min-width: fit-content !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  padding: 10px 16px !important;
}

.ac_left_btn_sel {
  min-width: fit-content !important;
  background: var(---secondary) !important;
  border-radius: 5px !important;
  justify-content: flex-start !important;
  text-transform: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---primary) !important;
  padding: 10px 16px !important;
}

.ac_right {
  display: flex;
  flex-direction: column;
  width: calc(100% - 265px);
  margin-left: 50px;
}

.ac_right_each {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.acc_body {
  display: flex;
  width: 100%;
}

/* account details */

.ad_left_ac {
  width: 49%;
}

.ad_right_ac {
  width: 49%;
  margin-left: 2%;
}

.ad_title_ac {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

/* address book */

.ab_add_btn {
  margin-left: auto !important;
  background: var(---primary) !important;
  border-radius: 12px !important;
  text-transform: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---white) !important;
  padding: 6px 25px !important;
}

.ab_modal {
  background-color: var(---white) !important;
}

.ab_input {
  background: var(---white);
  border: 1px solid var(---dark_gray);
  border-radius: 12px;
  width: 100% !important;
  height: 45px;
  outline: none;
  padding: 0 10px;
}

.each_shipping_address {
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  padding: 15px 25px;
  font-family: "Poppins" !important;
  color: var(---text) !important;
}

.each_shipping_address_sel {
  background: var(---almost_white) !important;
  border: 1px solid var(---primary) !important;
  border-radius: 12px !important;
  padding: 15px 25px !important;
  font-family: "Poppins" !important;
  color: var(---text) !important;
}

.esa_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(---text);
}

/* order history */

.each_order_history_div {
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  padding: 16px;
}

.elipsis_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.oh_icon {
  min-width: 20px !important;
  height: 20px !important;
}

.oh_text {
  position: relative;
  padding-left: 10px;
}

.oh_btn {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---primary) !important;
  border: 1px solid var(---primary) !important;
  border-radius: 12px !important;
  text-transform: none !important;
  width: 48% !important;
  height: 45px !important;
}

.oh_btn_track {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---white) !important;
  border: 1px solid var(---primary) !important;
  border-radius: 12px !important;
  text-transform: none !important;
  background-color: var(---primary) !important;
  width: 48% !important;
  height: 48px !important;
}

/* order one */

.order_again_btn {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---white) !important;
  border: 1px solid var(---primary) !important;
  border-radius: 12px !important;
  text-transform: none !important;
  background-color: var(---primary) !important;
  height: 45px !important;
  margin-left: auto !important;
}

.od_main {
  width: fit-content;
}

.od_left {
  width: 420px;
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
}

.od_left_pay_div {
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  padding: 12px 16px;
}

.od_right {
  width: 400px;
  margin-left: 50px;
  background: var(---almost_white);
  /* border: 1px solid var(---gray); */
  border-radius: 12px;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
}

/* billing info */

.bi_info {
  display: flex;
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  width: 716px !important;
  margin-bottom: 50px !important;
}

.bi_left {
  display: flex;
  flex-direction: column;
  width: 215px;
  padding: 20px 0px;
}

.bi_right {
  display: flex;
  flex-direction: column;
  width: calc(100% - 215px) !important;
  padding: 30px 50px;
}

.bi_left_btn {
  min-width: fit-content !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  padding: 10px 16px !important;
}

.bi_left_btn_sel {
  min-width: fit-content !important;
  background: var(---secondary) !important;
  justify-content: flex-start !important;
  text-transform: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---primary) !important;
  padding: 10px 16px !important;
  border-radius: 0px !important;
}

.bi_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(---text);
}

/* map */

.leaflet-container {
  height: 250px;
  width: 100%;
  margin-bottom: 20px;
}

.pickMapDiv {
  /* width: 400px !important; */
  /* height: 400px !important; */
  padding: 0px;
  overflow: hidden !important;
}

/* track order */

.to_items {
  display: flex;
  flex-direction: column;
}

.to__items-box {
  display: flex;
  flex-direction: column;
  width: 450px;
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  padding: 16px;
}

.to__items-image {
  width: 80px;
  height: 80px;
}

.to_map {
  width: 100%;
  height: 100%;
}

.to_pickMapDiv {
  width: 100% !important;
  height: 100% !important;
  padding: 0px;
  overflow: hidden !important;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

/*  */

.container_image {
  position: relative;
  width: 90px;
  height: 90px;
}

.user_image {
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  width: 30px;
  height: 30px;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container_image:hover .user_image {
  opacity: 0.9;
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.container_image:hover .middle {
  opacity: 1;
}

.container_image > input {
  display: none;
}

.oneOrder_body {
  display: flex;
}

.flexAcc {
  display: flex;
  width: 100%;
}

/* media query */

/* mobile */

@media only screen and (max-width: 600px) {
  .od_main {
    width: 100%;
  }

  .od_left {
    width: 100%;
  }

  .od_right {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  .oneOrder_body {
    flex-direction: column;
  }

  /* my account */
  .myAccountMain {
    flex-direction: column;
  }

  .ac_left {
    flex-direction: row;
    width: 100%;
    overflow: scroll;
  }

  .ac_left_btn {
    margin-bottom: 10px !important;
  }

  .ac_left_btn_sel {
    margin-bottom: 10px !important;
  }

  .ac_right {
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
  }

  .flexAcc {
    flex-direction: column;
  }

  /* account details */

  .ad_left_ac {
    width: 100%;
  }

  .ad_right_ac {
    width: 100%;
    margin-left: 0%;
  }

  /* address */
  .ab_add_btn {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  /* billing */
  .bi_info {
    flex-direction: column;
    width: 100% !important;
  }

  .bi_left {
    flex-direction: row;
    width: 100%;
  }

  .bi_right {
    width: 100% !important;
    padding: 20px;
  }
}

/* Portrait tablet 600-770*/

@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .od_main {
    width: 100%;
  }

  .od_left {
    width: 100%;
  }

  .od_right {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  .oneOrder_body {
    flex-direction: column;
  }

  /* my account */
  .myAccountMain {
    flex-direction: column;
  }

  .ac_left {
    flex-direction: row;
    width: 100%;
    overflow: scroll;
  }

  .ac_left_btn {
    margin-bottom: 10px !important;
  }

  .ac_left_btn_sel {
    margin-bottom: 10px !important;
  }

  .ac_right {
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
  }

  .flexAcc {
    flex-direction: column;
  }

  /* account details */

  .ad_left_ac {
    width: 100%;
  }

  .ad_right_ac {
    width: 100%;
    margin-left: 0%;
  }

  /* address */
  .ab_add_btn {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  /* billing */
  .bi_info {
    flex-direction: column;
    width: 100% !important;
  }

  .bi_left {
    flex-direction: row;
    width: 100%;
  }

  .bi_right {
    width: 100% !important;
    padding: 20px;
  }
}
