@import "../fonts.css";

.hp_cat_div {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  background-color: var(---white);
  margin-top: 10px;
}

.hp_cat_div_lg {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  background-color: var(---white);
  margin-top: 10px;
}

.hp_cat_btn {
  display: flex !important;
  width: 60px !important;
  height: 60px !important;
  padding: 0 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.hp_cat_icon {
  width: 50px !important;
  height: 50px !important;
}

/* past orders */

.hp_each_title_div {
  display: flex;
  height: 50px !important;
  align-items: center;
}

.hp_past_orders {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.hp_see_all {
  text-transform: none !important;
}

/* carousel */

.hp_carousel_div {
  display: flex;
  width: 100%;
  height: 300px;
  margin: 10px;
}

.item_inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.hp_carousel_item {
  height: 200px;
  padding: 2px;
  background-color: coral;
}

/* mobile */
@media only screen and (max-width: 600px) {
  .hp_cat_div_lg {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
