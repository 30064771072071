@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap");
@import "../fonts.css";

.admin_settings_body {
  display: flex;
  width: 500px;
  min-height: 360px;
  flex-direction: column;
  border-radius: 5px;
  padding: 25px;
  background-color: var(---white);
  overflow: hidden;
}

.admin_settings_btn_div {
  display: flex;
  margin-bottom: 30px;
  overflow-x: auto !important;
  scrollbar-width: thin !important;
}

.admin_settings_btn_eachDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.admin_settings_btn_sel {
  width: 200px !important;
  margin-right: 10px !important;
  height: 45px !important;
  border-radius: 12px !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  color: var(---primary) !important;
  text-transform: none !important;
  background-color: var(---secondary) !important;
}

.admin_settings_btn {
  width: 200px !important;
  margin-right: 10px !important;
  height: 45px !important;
  border-radius: 12px !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  color: var(---dark_gray) !important;
  text-transform: none !important;
}

.ad_btn_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.ad_forgot_btn {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  color: var(---link) !important;
  border-radius: 12px !important;
  text-transform: none !important;
  height: 48px !important;
}

.ad_change_btn {
  width: 150px !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  color: var(---white) !important;
  background: var(---primary) !important;
  border-radius: 12px !important;
  text-transform: none !important;
  margin-left: 10px !important;
  height: 48px !important;
}

/* loyalty */

.loyalty_input_div {
  display: flex;
  align-items: center;
}

.loyalty_title {
  font-family: "Poppins";
  font-weight: 600;
  margin-left: 35px;
}

.loyalty_input {
  width: 180px !important;
  outline: none !important;
  background: var(---white) !important;
  border: 1px solid var(---dark_gray) !important;
  border-radius: 12px !important;
  height: 45px !important;
  padding: 0 8px !important;
  margin-right: 6px !important;
}

/* tax */

.add_tax_btn {
  display: flex !important;
  align-self: center !important;
  width: 180px !important;
  border: 1px solid var(---primary) !important;
  border-radius: 12px !important;
  font-weight: 600 !important;
  color: var(---primary) !important;
  text-transform: none !important;
}

.each_tax {
  border: 1px solid var(---dark_gray) !important;
  border-radius: 12px !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  color: var(---dark_gray) !important;
  height: 50px !important;
  padding: 0 12px !important;
  margin-bottom: 20px !important;
  justify-content: left !important;
  text-transform: none !important;
}

.each_tax_value {
  font-weight: 400;
  color: var(---text);
}

/* kitchen status */

.kitchen_status_input_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.kitchen_status_input {
  width: 150px !important;
  outline: none !important;
  background: var(---white) !important;
  border: 1px solid var(---dark_gray) !important;
  border-radius: 12px !important;
  height: 45px !important;
  padding: 0 8px !important;
  margin-right: 6px !important;
}
