/*  */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.mapBox_maindiv {
  position: relative;
  margin-top: 20px;
}

.mapBox_main {
  width: 100%;
  height: 250px;
}

:root {
  /* ---primary: #8943ef; */
  ---primary: #8943ef;
  ---secondary: #f7f4db;
  ---white: #ffffff;
  ---text: #2e2e2e;
  ---almost_white: #f9f9f9;
  ---success: #32bf52;
  ---link: #192fa4;
  ---gray: #e9ecff;
  ---dark_gray: #818091;
  ---caution: #fac265;
  ---black: #000;
  ---border_color: #d5d5d5;
  ---purple: #8000ff;
  ---sky_blue: #3c91e6;
  ---csr_yellow: #fcee0a;
  ---csr_blue: #00f0ff;
  ---success-dark: #16b857;
  ---error-dark: #d81e04;
  ---text-primary: #222227;
  ---text-secondary: #9d9ca3;
  /* ---font_family: "Poppins"; */
  ---font_family: "Outfit", sans-serif; /* Updated to use "Outfit" font */
  ---font_weight_a: 400;
  ---font_weight_b: 500;
  ---font_weight_c: 600;
  ---font_size_8: 8px;
  ---line_height_10: 10px;
  ---font_size_10: 10px;
  ---line_height_12: 12px;
  ---font_size_12: 12px;
  ---line_height_18: 18px;
  ---font_size_14: 14px;
  ---line_height_21: 21px;
  ---font_size_16: 16px;
  ---font_size_20: 20px;
  ---line_height_20: 25px;
  ---line_height_24: 24px;
  ---font_size_24: 24px;
  ---line_height_36: 36px;
  ---font_size_28: 28px;
  ---line_height_28: 35px;
  ---font_size_36: 36px;
  ---line_height_64: 64px;
  ---font_size_18: 18px;
  ---line_height_32: 32px;
  ---font_size_72: 72px;
  ---line_height_108: 108px;
  ---font_size_40: 40px;
  ---line_height_60: 60px;
  ---font_size_elec: 24px;
  ---line-height_elec: 45px;
  font-family: var(---font_family) !important;
}

/* mobile */

@media only screen and (max-width: 600px) {
  :root {
    /* ---font_size_12: 10px; */
    /* ---line_height_18: 15px; */
    ---font_size_14: 12px;
    ---line_height_21: 18px;
    ---font_size_16: 14px;
    ---line_height_24: 18px;
    ---font_size_24: 18px;
    ---line_height_36: 30px;
    ---font_size_28: 20px;
    ---line_height_28: 30px;
    ---font_size_36: 30px;
    ---line_height_64: 45px;
    ---font_size_18: 16px;
    ---line_height_32: 28px;
    ---font_size_72: 40px;
    ---line_height_108: 60px;
    ---font_size_40: 25px;
    ---line_height_60: 37.5px;
    ---font_size_elec: 21px;
    ---line-height_elec: 30px;
  }
}

/* btn text transform none */

.btn_trfm {
  text-transform: none !important;
}

/* font colors */

.c1 {
  color: var(---primary) !important;
}

.c2 {
  color: var(---secondary) !important;
}

.c3 {
  color: var(---text) !important;
}

.c4 {
  color: var(---white) !important;
}

.c5 {
  color: var(---almost_white) !important;
}

.c6 {
  color: var(---caution) !important;
}

.c7 {
  color: var(---dark_gray) !important;
}

.c8 {
  color: var(---gray) !important;
}

.c9 {
  color: var(---link) !important;
}

.c10 {
  color: var(---success) !important;
}

.c-pri{
  color: var(---text-primary) !important;
}

.c-secondary {
  color: var(---text-secondary) !important ;
}

/* font weight */

/* 400 */
.w1 {
  font-weight: var(---font_weight_a) !important;
}

/* 500 */

.w2 {
  font-weight: var(---font_weight_b) !important;
}

/* 600 */

.w3 {
  font-weight: var(---font_weight_c) !important;
}

/* font size */

/* 8px */
.s-8 {
  font-size: var(---font_size_8) !important;
  line-height: var(---line_height_10) !important;
}

/* 10px */
.s-10 {
  font-size: var(---font_size_10) !important;
  line-height: var(---line_height_12) !important;
}

/* 12px */
.s-12 {
  font-size: var(---font_size_12) !important;
  line-height: var(---line_height_18) !important;
}

/* 14px */
.s1 {
  font-size: var(---font_size_14) !important;
  line-height: var(---line_height_21) !important;
}

/* 16px */

.s2 {
  font-size: var(---font_size_16) !important;
  line-height: var(---line_height_24) !important;
}

/* 24px */

.s3 {
  font-size: var(---font_size_24) !important;
  line-height: var(---line_height_36) !important;
}

/* 36px */

.s4 {
  font-size: var(---font_size_36) !important;
  line-height: var(---line_height_64) !important;
}

.elec {
  font-size: var(---font_size_elec) !important;
  line-height: var(---line_height_elec) !important;
}

/* 18px */
.s5 {
  font-size: var(---font_size_18) !important;
  line-height: var(---line_height_32) !important;
}

/* 72px */
.s-72 {
  font-size: var(---font_size_72) !important;
  line-height: var(---line_height_108) !important;
}

/* 40px */
.s-40 {
  font-size: var(---font_size_40) !important;
  line-height: var(---line_height_60) !important;
}

.s-20 {
  font-size: var(---font_size_20) !important;
  line-height: var(---line_height_20) !important;
}

.s-28 {
  font-size: var(---font_size_28) !important;
  line-height: var(---line_height_28) !important;
}

/* background */

.bg-pri {
  background-color: var(---primary) !important;
}

.bg-sec {
  background-color: var(---secondary);
}

.bg-gray {
  background-color: var(---gray) !important;
}

.bg-almost-white {
  background-color: var(---almost_white) !important;
}

.bg-sec-text {
  background-color: var(---text-secondary) !important;
}

.bg-success-dark {
  background-color: var(---success-dark) !important;
}

.bg-error-dark {
  background-color: var(---error-dark) !important;
}

/* border & radius */

.divider {
  border: 1px solid #e9ecff !important;
  margin: 12px 0 !important;
}

.divider_csr {
  border: 1px solid #545356 !important;
  margin: 12px 0 !important;
}

.bor-primary {
  border: 1px solid var(---primary) !important;
}

.bor {
  border: 1px solid var(---gray) !important;
}

.bor_dark {
  border: 1px solid var(---dark_gray) !important;
}

.bor_rd_sm {
  border: 1px solid var(---gray) !important;
  border-radius: 5px !important;
}

.bor_rd_lg {
  border: 1px solid var(---gray) !important;
  border-radius: 12px !important;
}

.rounded-5{
  border-radius: 5px !important;
}

/* buttons */

.primary_btn {
  height: 50px !important;
  background: #8943ef !important;
  border-radius: 12px !important;
  color: #ffffff !important;
  padding: 10px 50px !important;
  font-weight: var(---font_weight_c) !important;
  font-size: var(---font_size_b) !important;
  line-height: var(---line_height_b) !important;
  text-transform: none !important;
}

.secondary_btn {
  height: 50px !important;
  border-radius: 12px !important;
  color: #8943ef !important;
  border: 1px solid #8943ef !important;
  padding: 10px 50px !important;
  font-weight: var(---font_weight_c) !important;
  font-size: var(---font_size_b) !important;
  line-height: var(---line_height_b) !important;
  text-transform: none !important;
}

.borderless_btn {
  height: 50px !important;
  color: var(---primary) !important;
  padding: 10px 50px !important;
  font-weight: var(---font_weight_c) !important;
  font-size: var(---font_size_b) !important;
  line-height: var(---line_height_b) !important;
  text-transform: none !important;
}

.borderless_btn_black {
  height: 50px !important;
  color: var(---text) !important;
  padding: 10px 50px !important;
  font-weight: var(---font_weight_c) !important;
  font-size: var(---font_size_b) !important;
  line-height: var(---line_height_b) !important;
  text-transform: none !important;
}

.align_btn_items_l {
  justify-content: left !important;
  padding-inline: 0px !important;
}

.btn_left_text {
  justify-content: flex-start !important;
}

/* text justify */

.justify_text {
  text-align: justify;
  text-justify: inter-word;
}

.cursor_pointer {
  cursor: pointer !important;
}

/* extra */

.hover_link {
  color: var(---text);
}

.hover_link:hover {
  text-decoration: underline var(---link);
  color: var(---link);
}

.cen {
  text-align: center;
}

.w-fit-content {
  width: fit-content !important;
}

.underline {
  text-decoration: underline;
}

.strikethrough {
  text-decoration: line-through;
}

.text-align-center {
  text-align: center;
}

.text-transform-none {
  text-transform: none !important;
}
