@import "../fonts.css";

.cus_body {
  display: flex;
  width: 95%;
}

.cus_body_left {
  display: flex;
  flex-direction: column;
  width: 265px;
  padding: 25px;
  justify-content: center;
  align-items: center;
  background-color: var(---white);
  border-radius: 12px;
  overflow: hidden;
}

.cus_image {
  width: 90px !important;
  height: 90px !important;
  margin: 0px auto !important;
  margin-bottom: 10px !important;
}

.cus_loyal_btn {
  width: fit-content !important;
  background: var(---secondary) !important;
  border-radius: 12px !important;
  text-transform: none !important;
}

.cus_loyal_text {
  font-weight: 600;
  color: var(---primary);
  margin-left: 0.4rem;
}

.cus_loyal_value {
  color: var(---text);
}

.cus_name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 40px;
  word-break: break-all;
}

.cus_phone {
  color: var(---dark_gray);
  word-break: break-all;
}

.cus_blacklist {
  display: flex;
  width: 178px;
  height: 44px;
  border-radius: 12px;
  padding: 10px, 21px, 10px, 21px;
  justify-content: center;
  align-items: center;
  color: #8943ef;
  border: 1px solid #8943ef;
  margin-top: 8px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.cus_body_right {
  display: flex;
  flex-direction: column;
  width: fit-content;
  /* width: calc(100% - 280px); */
  padding: 25px;
  background-color: var(---white);
  border-radius: 12px;
  margin-left: 15px;
}
