@import "../fonts.css";

.vo_body {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 5px;
  padding: 25px;
  background-color: var(---white);
  overflow: hidden;
}

.vo_btn_div {
  display: flex;
  overflow-x: hidden !important;
  margin: 0 auto;
  margin-bottom: 30px;
}

.vo_btn_eachDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.vo_btn_sel {
  width: 180px !important;
  margin-right: 10px !important;
  height: 40px !important;
  border-radius: 25px !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---primary) !important;
  text-transform: none !important;
  background-color: var(---secondary) !important;
}

.vo_btn {
  width: 180px !important;
  margin-right: 10px !important;
  height: 40px !important;
  border-radius: 25px !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---dark_gray) !important;
  text-transform: none !important;
}

.sc_promo {
  display: flex;
  flex-direction: column;
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  padding: 25px;
  color: var(---text) !important;
}

.sc_promo_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(---text) !important;
  margin-bottom: 10px;
}

.testing_shape {
  display: flex !important;
  height: 40px !important;
  background-color: var(---secondary) !important;
  padding: 0 !important;
}

.first_shape {
  width: 20px;
  height: 20px;
  margin: auto 0;
  border-radius: 0 10px 10px 0;
  background-color: var(---white);
}

.middle_shape {
  width: fit-content;
  background-color: var(---secondary);
  padding: 0 10px;
}

.last_shape {
  width: 20px;
  height: 20px;
  margin: auto 0;
  border-radius: 10px 0 0 10px;
  background-color: var(---white);
}

.sc_promo_copy {
  background-color: var(---primary) !important;
  color: var(---white) !important;
  margin-left: 10px !important;
}

/* media */

/* mobile */

@media only screen and (max-device-width: 599px) {
  .vo_body {
    padding: 0px;
  }

  .vo_btn_sel {
    width: 40vw !important;
  }

  .vo_btn {
    width: 40vw !important;
  }
}
