@import "../fonts.css";

.category_top_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.category_body {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: calc(100% - 50px) !important;
}

.table_dot_icon {
  color: var(---text) !important;
  font-weight: bold !important;
}

/* add categorry */

.add_category_body {
  display: flex;
  width: 400px;
  flex-direction: column;
  background: var(---white);
  border-radius: 5px;
  padding: 15px 25px;
}

.add_category_image_box {
  display: flex;
  background: var(---almost_white);
  border: 1px dashed #0C72DE;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 220px !important;
  overflow: hidden !important;
}

.upload-btn-wrapper-show {
  position: relative;
  overflow: hidden;
  display: inline-block;
  background-color: var(---almost_white);
  width: 100%;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  border: 1px dashed #0C72DE;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  background-color: var(---almost_white);
  width: 100%;
  height: 220px !important;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-btn-wrapper-show input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.add_category_image_div {
  max-width: 100%;
  height: 200px;
  margin: auto;
}

.add_category_image {
  width: 100%;
  height: 100%;
}

.browseText {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: var(---text);
}

.browseTextBold {
  font-weight: bold;
  color: #0C72DE;
}

.add_category_input {
  display: flex !important;
  width: 100% !important;
  outline: none !important;
  background: var(---white) !important;
  border: 1px solid var(---dark_gray) !important;
  border-radius: 12px !important;
  height: 40px !important;
  padding: 0 8px !important;
}

/*  media query */

/* mobile */

@media only screen and (max-width: 600px) {
  /* add categorry */
  .add_category_body {
    width: 100%;
    padding: 15px;
  }
}
