@import "../fonts.css";

.search_tab_div {
  display: flex;
}

.sp_left {
  background-color: #fff !important;
  display: flex !important;
  flex-direction: column !important;
  height: fit-content !important;
  border: 1px solid var(---gray) !important;
  border-radius: 12px !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  /* top: 20px !important; */
}

.sp_left_first {
  padding: 16px 24px;
}

.sp_right {
  height: fit-content;
}

.spr_each {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  margin-right: 15px;
  align-items: center;
}

.spr_each_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(---text);
}

.spr_brand_img {
  width: 150px !important;
  height: 150px !important;
  border-radius: 12px;
  margin-bottom: 3;
}

.sort_xs_btn_each {
  height: 35px !important;
  padding: 0 !important;
  font-weight: var(--font_weight_c) !important;
  font-size: var(--font_size_b) !important;
  line-height: var(--line_height_b) !important;
  text-transform: none !important;
  justify-content: flex-start !important;
}

.sc_combo_div {
  display: flex;
  flex-direction: column;
  /* width: 285px; */
  height: 100% !important;
  margin-right: 15px;
  padding: 0.5em;
}

.sc_cat_icon_search {
  width: 50px !important;
  height: 50px !important;
}

.sc_cat_search {
  display: flex !important;
  flex-direction: column !important;
  width: 200px !important;
  height: 66px !important;
  margin-right: 20px !important;
  margin-left: 2px !important;
}

.sc_cat_each_search {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 200px !important;
  height: 66px !important;
  background-color: var(---almost_white) !important;
  font-family: "Poppins" !important;
  padding: 8px 22px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  text-transform: none !important;
}

/* media query */

/* mobile */

@media only screen and (max-width: 600px) {
  .sc_combo_div {
    width: 100%;
    margin-right: 0px;
    padding: 0em;
  }

  .spr_each {
    margin-right: 0px;
    align-items: flex-start;
  }

  .spr_brand_img {
    display: none;
    margin-bottom: 0px;
  }

  .spr_each_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .sc_cat_search {
    width: 100% !important;
    height: 30px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .sc_cat_each_search {
    width: 100% !important;
    height: 20px !important;
    background-color: transparent !important;
    padding: 15px 0 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
  }
}
