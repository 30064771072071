.alice-carousel__wrapper {
  border: solid 1px rgba(67, 87, 148, 0.3);
}

li.alice-carousel__dots-item.__custom {
  vertical-align: middle;
  cursor: pointer;
  padding: 5px;
}

.thumbs {
  max-width: 500px;
  margin: 30px auto;
  padding: 0 100px;
  position: relative;
}

.thumbs .btn-prev,
.thumbs .btn-next {
  position: absolute;
  top: 25px;
  font-size: 50px;
  color: #c7cdde;
  cursor: pointer;
}

.thumbs .btn-prev {
  left: 25px;
}

.alice-carousel__stage-item.__target::after {
  border: none !important;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.thumbs .btn-next {
  right: 25px;
}

.alice-carousel__stage-item.__active:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border: solid 1px rgba(0, 0, 0, 0);
  transition: border 0.8s;
}

.alice-carousel__stage-item.__target:after {
  border: solid 1px rgba(63, 81, 181, 0.8);
}

.thumb,
.thumb > .item {
  width: 200px;
  height: 100px;
}

.item {
  height: 320px;
  width: 300px;
  /* padding: 2px; */
  box-sizing: border-box;
  font-size: 0;
  color: rgba(0, 0, 0, 0);
}

.item:before {
  /* content: attr(data-value); */
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 300;
  user-select: none;
  background-color: #5f5fe4;
}

.alice_item_image {
  width: 100%;
  height: 220px;
}

.item-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 1rem;
}

.app {
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: var(---white);
}

.app .title {
  font-weight: 300;
  font-style: italic;
  color: #435794;
}

.app .title span {
  text-transform: capitalize;
}

.app .anchor {
  opacity: 0.5;
}

.app .anchor:hover {
  opacity: 1;
}

.s-main {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  min-width: 320px;
  box-sizing: border-box;
  margin: auto;
  overflow-x: hidden;
  padding: 20px;
  height: auto;
  padding-top: 0;
}

.s-render-components {
  color: #435794;
}

.b-refs-buttons {
  margin: 15px auto;
  text-align: center;
}

.b-refs-navs {
  margin-top: 20px;
  text-align: center;
}

.b-refs-navs i {
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 5px;
  background-color: #c7cdde;
  border-radius: 6px;
}

.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.alice-carousel .animated-out {
  z-index: 1;
}

.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.alice-carousel {
  position: relative;
  width: 100%;
  margin: auto;
  direction: ltr;
}

.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  vertical-align: top;
  white-space: normal;
  line-height: 0;
}

.alice-carousel__stage-item * {
  line-height: initial;
}

.alice-carousel__stage-item.__hidden {
  opacity: 0;
  overflow: hidden;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 10px 5px;
}

.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  position: relative;
  content: attr(data-area);
  text-transform: capitalize;
}

.alice-carousel__prev-btn {
  text-align: right;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #465798;
}

.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: darkred;
}

.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
  opacity: 0.4;
  pointer-events: none;
}

.alice-carousel__play-btn {
  position: absolute;
  top: 30px;
  left: 20px;
  display: inline-block;
}

.alice-carousel__play-btn:hover {
  cursor: pointer;
}

.alice-carousel__play-btn-wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 50%;
  background-color: var(---white);
}

.alice-carousel__play-btn-item {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: rgba(0, 0, 0, 0);
}

.alice-carousel__play-btn-item::before,
.alice-carousel__play-btn-item::after {
  position: absolute;
  pointer-events: none;
  display: block;
  width: 0;
  height: 0;
  content: "";
  transition: all 0.4s linear;
  border-width: 8px 0 8px 15px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  border-left-color: #465798;
}

.alice-carousel__play-btn-item::before {
  left: 5px;
  height: 14px;
}

.alice-carousel__play-btn-item::after {
  top: 7px;
  left: 18px;
}

.alice-carousel__play-btn-item.__pause::before,
.alice-carousel__play-btn-item.__pause::after {
  height: 30px;
  border-width: 0 0 0 10px;
}

.alice-carousel__play-btn-item.__pause::after {
  top: 0;
  left: 18px;
}

.alice-carousel__dots {
  margin: 30px 3px 5px;
  padding: 0;
  list-style: none;
  text-align: center;
}

.alice-carousel__dots > li {
  display: inline-block;
}

.alice-carousel__dots-item:not(.__custom) {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e0e4fb;
}

.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 20px;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #6e7ebc;
}

.alice-carousel__slide-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6);
}

.alice-carousel__slide-info-item {
  vertical-align: middle;
  line-height: 0;
}

/*  */

.item_brand {
  height: 190px;
  width: 170px;
  box-sizing: border-box;
  font-size: 0;
  color: rgba(0, 0, 0, 0);
}

.item_brand:before {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 300;
  user-select: none;
  background-color: #5f5fe4;
}

.alice_item_image_brand {
  width: 130px !important;
  height: 130px !important;
}

.alice_item_image_brand_a {
  width: 130px !important;
  height: 130px !important;
  /* width: 580px !important; */
  /* height: 200px !important; */
}

/*  */

.mirchi_div {
  position: relative;
}

.mirchi {
  width: 30px !important;
  height: 30px !important;
  border: none !important;
}

.mirchi_line {
  position: absolute;
  width: 25px;
  height: 2px;
  background-color: var(---text);
  top: 15px;
  transform: rotate(135deg);
}
