@import "../fonts.css";

/* single add */

.add_single_item_body {
  display: flex;
  flex-direction: column;
  width: 900px;
  background: var(---white);
  border-radius: 5px;
  padding: 15px 25px;
  justify-content: space-between;
}

.single_item_left {
  display: flex;
  flex-direction: column;
  width: 49%;
}
