@import "../fonts.css";

.nt {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.nt_left {
  display: flex;
  flex-direction: column;
  width: 300px;
  /* width: 20%; */
  height: fit-content;
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  height: 72vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.nt_left__each {
  border-bottom: 1px solid var(---gray);
  padding: 16px;
  cursor: pointer;
}

.nt_left__each_sel {
  border-bottom: 1px solid var(---gray);
  background-color: var(---almost_white);
  padding: 16px;
  cursor: pointer;
}

.ad_nt_left__each_sel {
  border-bottom: 1px solid var(---gray);
  background-color: var(---almost_white);
  padding: 16px;
  cursor: pointer;
}

.nt_right {
  display: flex;
  flex-direction: column;
  width: calc(100% - 450px);
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  margin-left: 50px;
  padding: 50px;
  height: fit-content;
}
