@import "../fonts.css";

.ad_or_top_btn {
  display: flex !important;
  justify-content: space-between !important;
  /* width: 250px !important; */
  height: 45px !important;
  background-color: var(---secondary) !important;
  color: var(---text) !important;
  text-transform: none !important;
  border-radius: 12px !important;
  padding: 0 20px !important;
  font-family: "Poppins" !important;
  margin-right: 24px !important;
}

.ad_or_top_btn_text {
  width: 200px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ad_or_top_btn_date {
  width: 320px !important;
  height: 45px !important;
  background-color: var(---secondary) !important;
  color: var(---text) !important;
  text-transform: none !important;
  border-radius: 12px !important;
  font-family: "Poppins" !important;
  margin-right: 24px !important;
  border: none !important;
  outline: none !important;
}

.ad_or_top_btn_2 {
  display: flex !important;
  justify-content: space-between !important;
  width: 200px !important;
  height: 45px !important;
  background-color: var(---primary) !important;
  color: var(---white) !important;
  text-transform: none !important;
  border-radius: 12px !important;
  padding: 0 40px !important;
  font-family: "Poppins" !important;
  margin-right: 24px !important;
}

/* view order */

.ov_top {
  font-weight: 600;
  color: var(---text);
  margin-left: 10px;
}

.ov_left {
  width: 100%;
  margin-right: 20px;
}

.ov_img {
  width: 90px !important;
  height: 90px !important;
  margin: 0 auto !important;
}

.ov_right {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-left: auto;
  background: var(---white);
  border-radius: 12px 12px 0px 0px;
  padding: 16px 10px 0px 10px;
}

.ovr_name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(---text);
  margin: 0 auto;
  margin-top: 10px;
}

.ovr_phone {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(---dark_gray);
  margin: 0 auto;
}

.ovr_red {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(---primary);
}

.ovr_note {
  width: 300px;
  background: var(---secondary);
  border-radius: 0px 0px 12px 12px;
  padding: 16px;
}

.del_assign {
  width: 400px;
  background: var(---white);
  border-radius: 12px;
  padding: 24px;
}

.del_assign_view {
  width: fit-content;
  background: var(---white);
  border-radius: 12px;
  padding: 24px;
}

.del_assign_box {
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  padding: 16px;
}

.del_assign_btn {
  height: 20px !important;
}

.print_bill_btn {
  border: 1px solid #000 !important;
  height: 50px !important;
  padding-inline: 20px !important;
  color: #000 !important;
}

/* create */

.ado_create_div {
  display: flex;
  width: 920px;
  background: var(---white);
  border-radius: 12px;
  padding: 24px;
  justify-content: space-between;
}

.adoc_left {
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-right: 4%;
}

.adoc_right {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.adocr_right_items_div {
  border: 1px solid var(---dark_gray);
  border-radius: 12px;
  padding: 10px;
}

.adocl_edit {
  border-radius: 12px !important;
  color: var(---link) !important;
  text-transform: none !important;
  font-weight: 600 !important;
  height: 45px !important;
}

.adocr_edit_btn {
  text-transform: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---link) !important;
  padding: 0 !important;
  justify-content: flex-end !important;
}

.adocr_btn {
  border: 1px solid var(---primary) !important;
  border-radius: 12px !important;
  color: var(---primary) !important;
  text-transform: none !important;
  font-weight: 600 !important;
  height: 45px !important;
}

.adocr_select {
  width: 100%;
  background: var(---white);
  border: 1px solid var(---dark_gray);
  border-radius: 12px;
  padding: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(---text);
}

.adocr_cancel {
  border-radius: 12px !important;
  color: var(---primary) !important;
  text-transform: none !important;
  font-weight: 600 !important;
  padding: 6px 30px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  height: 45px !important;
  width: 35% !important;
}

.adocr_add_btn {
  border: 1px solid var(---primary) !important;
  background-color: var(---primary) !important;
  border-radius: 12px !important;
  color: var(---white) !important;
  text-transform: none !important;
  font-weight: 600 !important;
  margin-left: 10px !important;
  padding: 6px 30px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  height: 45px !important;
  width: 65% !important;
}

.adocr_update_btn {
  border: 1px solid var(---primary) !important;
  background-color: var(---primary) !important;
  border-radius: 12px !important;
  color: var(---white) !important;
  text-transform: none !important;
  font-weight: 600 !important;
  margin-left: 10px !important;
  padding: 6px 30px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  height: 45px !important;
  width: 45% !important;
}



/*  */

/* Portrait iPad */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1025px) 
  and (-webkit-min-device-pixel-ratio: 1) 
  {
    .adoc_left {
      width: 100%;
    }
    .adoc_right {
      width: 100%;
    }
}