@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  font-family: "Poppins" !important;
  scrollbar-width: thin !important;
  scroll-behavior: smooth !important;
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

@media print {
  @page {
    size: auto !important;
    margin: 0mm !important;
    /* margin-top: 50px !important; */
    color: #000 !important;
  }

  @media all {
    .pagebreak {
      overflow: visible;
    }
  }
}

@media screen {
  .printNeo {
    visibility: hidden;
    height: 0;
    font-family: "Arial" !important;
    font-style: normal !important;
  }
}

.printNeoBody {
  font-family: "Arial" !important;
  font-style: normal !important;
  margin: 0 !important;
  width: calc(84%) !important;
  height: 100% !important;
  margin-left: 8% !important;
  /* font-size: 50px !important; */
  /* line-height: 70px !important; */
}

.dividerNeo {
  height: 4px solid #000 !important;
  border-top: 6px solid #000 !important;
  margin: 8% 0 !important;
  margin-top: 50px !important;
}

.neoPrice {
  width: 180px !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
