@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import "../fonts.css";

p {
  margin-bottom: 0.5em;
}

.market_layout_main {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}

/* width */
.market_width {
  width: 1200px !important;
  margin: 0 auto !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(---text) !important;
  min-height: calc(100% - 286px);
}

.market_width_main {
  width: 1200px !important;
  margin: 0 auto !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(---text) !important;
  min-height: calc(100vh - 286px);
}

.market_width_main_whoweare {
  width: 100% !important;
  margin: 0 auto !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(---text) !important;
  min-height: calc(100% - 286px);
}

.market_width_toolbar_main {
  width: 1200px !important;
  margin: 0 auto !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(---text) !important;
  min-height: calc(100% - 286px);
}

.market_width_toolbar {
  width: 100% !important;
  margin: 0 auto !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(---text) !important;
  min-height: 100% !important;
}

.market_width_footer {
  width: 1200px !important;
  margin: 0 auto !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(---text) !important;
  min-height: calc(100% - 286px);
}

.ml_toolbar {
  height: 50px;
}

.market_width_main_a {
  position: absolute;
  top: -10;
}

.market_appbar {
  height: 64px !important;
}

.text_st {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
}

.text_stb {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
}

.ant-dropdown {
  z-index: 9999;
}

.acc_dropdownBtn {
  font-family: "Poppins" !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(---text) !important;
  text-transform: none !important;
}

.nova_title_logo {
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: var(---primary);
  cursor: pointer;
}

.ml_top {
  display: flex;
  align-items: center;
  height: fit-content;
}

.ml_location {
  width: 200px !important;
  margin-left: 30px !important;
  background: var(---white) !important;
  text-transform: none !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---dark_gray) !important;
  border: 1px solid var(---gray) !important;
  border-radius: 12px !important;
  justify-content: flex-start !important;
  padding-left: 20px !important;
}

.ml_location_modal {
  width: 350px !important;
  height: 55px !important;
}

.ml_icon {
  margin-left: 5px !important;
}

.ml_search_items {
  width: 400px !important;
  color: var(---dark_gray) !important;
  justify-content: flex-start !important;
  height: auto !important;
  outline: none !important;
}

.ml_search_items_x {
  height: 20px !important;
}

.ant-select-dropdown {
  z-index: 9999;
}

.notifi_each {
  width: 100%;
  border: 1px solid var(---gray);
  border-radius: 12px;
  padding: 16px 12px;
}

.displayNoneLg {
  display: none !important;
}

.displayOnLg {
  display: block !important;
}

.displayNoneMbCat {
  display: flex;
  align-items: center;
}

.displayOnMbCat {
  display: none;
}

/* drawer */

.mobile_drawer_logo {
  width: 114px;
  margin: 25px 24px;
}

.md_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.md_body_btn {
  width: 100% !important;
  height: 45px !important;
  justify-content: flex-start !important;
  padding-left: 32px !important;
  border-radius: 0px !important;
  color: var(---text) !important;
}

.md_body_btn_sel {
  color: var(---primary) !important;
  width: 100% !important;
  height: 45px !important;
  justify-content: flex-start !important;
  padding-left: 32px !important;
  border-radius: 0px !important;
}

/* login */

.market_auth_left {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.market_auth_right {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 100px;
  padding-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: auto;
}

.market_login_reg_text {
  font-weight: 600 !important;
  color: var(---link) !important;
  cursor: pointer;
  margin-left: 10px;
}

.market_otp {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(---secondary);
  justify-content: center;
  align-items: center;
}

.market_otp_body {
  overflow: hidden;
}

.lm_fade_load_main {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
  z-index: 999;
  padding: 20px;
}

.lm_fade_load {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

/* mobile */

.mb_search_input {
  height: 40px;
  border: 1px solid var(---dark_gray);
  border-radius: 12px 0 0 12px;
  border-right: 0px;
  padding: 13px 19px;
  outline: none !important;
}

.mb_search_input_icon_x {
  height: 40px !important;
  border: 1px solid var(---dark_gray) !important;
  border-radius: 0 12px 12px 0 !important;
  border-left: 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ml_mobile_search_div {
  width: calc(100% - 50px);
  margin-right: 10px;
}

.ml_mobile_last {
  width: 35px;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.kitchenBusyToolbar {
  display: flex !important;
  min-height: 10px !important;
  height: 35px !important;
  color: #fff !important;
}

.kitchenBusyToolbarBlack {
  background-color: #8943ef !important;
}
.kitchenBusyToolbarRed {
  background-color: #8943ef !important;
}

/* media query */

@media (orientation: portrait) {
  .market_width_main {
    width: 95% !important;
    margin: 0 auto !important;
    padding: 10px;
  }
}

/* mobile */

@media only screen and (max-width: 600px) {
  .kitchenBusyToolbar {
    min-height: 10px !important;
    margin-top: 10px !important;
    height: fit-content !important;
    padding-bottom: 5px !important;
  }

  .displayNoneMbCat {
    display: none;
  }

  .displayOnMbCat {
    display: flex;
    flex-direction: column;
  }

  /* login */

  .market_auth_left {
    width: 0%;
  }

  .market_auth_right {
    width: 100%;
    align-items: center;
    padding: 10px;
  }

  /* otp */

  .market_otp_body {
    width: 95%;
    overflow: hidden;
  }

  .otp_class {
    width: 95vw;
    margin-left: 20px;
  }

  /*  */

  .market_width_main {
    width: 95% !important;
    margin: 0 auto !important;
    padding: 10px;
  }

  .market_width {
    width: 100% !important;
    margin: 0 auto !important;
    padding: 0px;
  }

  .market_width_toolbar_main {
    width: 100% !important;
    height: 100% !important;
  }

  .market_width_toolbar {
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto !important;
    padding: 0 5px !important;
  }

  .ml_toolbar {
    height: 70px;
  }

  .ml_search_items {
    width: 100% !important;
    margin-left: 5px;
  }

  .displayNoneLg {
    display: block !important;
  }

  .displayOnLg {
    display: none !important;
  }

  .market_appbar {
    height: 89px !important;
  }
}

/* Portrait tablet 600-770*/

@media only screen and (min-device-width: 600px) and (max-device-width: 767px) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  /* login */

  .market_auth_left {
    width: 0%;
  }

  .market_auth_right {
    width: 100%;
    align-items: center;
    padding: 10px;
  }

  /* otp */

  .market_otp_body {
    width: 95%;
    overflow: hidden;
  }

  .otp_class {
    width: 95vw;
    margin-left: 20px;
  }

  /*  */

  .market_width_main {
    width: 98% !important;
    margin: 0 auto !important;
    padding: 10px;
  }

  .market_width {
    width: 98% !important;
    margin: 0 auto !important;
    padding: 10px;
  }

  .market_width_toolbar_main {
    width: 100vw !important;
    margin: 0 !important;
    min-height: calc(100% - 286px);
  }

  .market_width_toolbar {
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto !important;
    padding: 0 5px !important;
  }

  .ml_toolbar {
    height: 90px;
  }

  .displayNoneLg {
    display: block !important;
  }

  .displayOnLg {
    display: none !important;
  }

  .market_appbar {
    height: 89px !important;
  }
}

/* Portrait tablet*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  /* login */

  .market_auth_left {
    width: 0%;
  }

  .market_auth_right {
    width: 100%;
    align-items: center;
    padding: 10px;
  }

  /* otp */

  .market_otp_body {
    width: 95%;
    overflow: hidden;
  }

  .otp_class {
    width: 95vw;
    margin-left: 20px;
  }

  /*  */
  .market_width_main {
    width: 98% !important;
    margin: 0 auto !important;
    padding: 10px;
  }

  .market_width {
    width: 98% !important;
    margin: 0 auto !important;
    padding: 10px;
  }

  .ml_toolbar {
    height: 0px;
  }

  .market_width_toolbar_main {
    width: 100vw !important;
    margin: 0 !important;
    min-height: calc(100% - 286px);
  }

  .market_width_toolbar {
    width: 95% !important;
    height: 100% !important;
    margin: 0 auto !important;
    padding: 0 5px !important;
  }

  .displayNoneLg {
    display: block !important;
  }

  .displayOnLg {
    display: none !important;
  }

  .market_appbar {
    height: 89px !important;
  }
  .kitchenBusyToolbar {
    min-height: 10px !important;
    margin-top: 15px !important;
    height: fit-content !important;
    padding-bottom: 5px !important;
  }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1200px) and (orientation: portrait) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .market_width_main {
    width: 98% !important;
    margin: 0 auto !important;
    padding: 10px;
  }

  .ml_toolbar {
    height: 0px;
  }

  .market_width_toolbar_main {
    width: 100vw !important;
    margin: 0 !important;
    min-height: calc(100% - 286px);
  }

  .market_width_toolbar {
    width: 95% !important;
    height: 100% !important;
    margin: 0 auto !important;
    padding: 0 5px !important;
  }

  .displayNoneLg {
    display: block !important;
  }

  .displayOnLg {
    display: none !important;
  }
}

/* Landscape tablet*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .market_width_main {
    width: 98% !important;
    margin: 0 auto !important;
  }

  .market_width {
    width: 98% !important;
    margin: 0 auto !important;
  }
}

/* ipad Mini Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .market_width_main {
    width: 98% !important;
    margin: 0 auto !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 1199px) and (orientation: landscape) {
  .market_width_main {
    width: 95% !important;
  }
}
