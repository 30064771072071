@import "../fonts.css";

.masonry-container {
  width: 100%;
  margin: auto;
  margin-top: 10px;
}

.masonry-container-empty {
  display: flex;
  height: calc(100vh - 200px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
}

.mce_img {
  width: 421px;
  height: 316px;
  margin-bottom: 50px;
}

.mce_text {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: var(---dark_gray);
}

.tile {
  width: 311px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 4px;
  padding: 16px 18px;
  border-radius: 12px;
}

.tile_each_btn {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  text-transform: none !important;
  align-items: flex-start !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  color: inherit !important;
  padding-bottom: 0px !important;
}

.tile_each_btn_title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: left;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text);
}

.tile_number {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.tile_note {
  background: var(---secondary);
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.tile_mod_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(---dark_gray);
}

.tile_mod_spice_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}

.masonry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex-grow: 1;
}

.start_cook_btn {
  width: 100% !important;
  height: 45px !important;
  background: var(---primary) !important;
  border-radius: 12px !important;
  font-family: "Poppins" !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---white) !important;
  text-transform: none !important;
}

.finish_cook_btn {
  width: 100% !important;
  height: 45px !important;
  background: var(---success) !important;
  border-radius: 12px !important;
  font-family: "Poppins" !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---white) !important;
  text-transform: none !important;
}

.ad_dash_top_kds {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.line_through_text {
  text-decoration: line-through;
}
