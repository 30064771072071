@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import "../../css/fonts.css";

.card button:hover {
  background-color: var(---sky_blue);
}

.text-info {
  padding: 1rem 1.5rem;
  margin-bottom: 2.5rem;
}

.controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}

.controls button {
  width: 40px;
  height: 40px;
  border: 0;
  background-color: var(---sky_blue);
  color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
}

.controls button:hover {
  background-color: var(---sky_blue);
}

.controls button:nth-child(1) {
  margin-right: 2rem;
}

/*  */

.sc_brand {
  display: flex;
  flex-direction: column;
  color: var(---black) !important;
  padding: 10px;
}

.sc_brand_skel {
  width: 150px !important;
  height: 150px !important;
  border-radius: 150px !important;
  margin-right: 20px !important;
}

.sc_brand_sel {
  display: flex !important;
  flex-direction: column !important;
  width: 578px !important;
  height: 200px !important;
  margin-right: 20px !important;
  margin-left: 2px !important;
}

.sc_cat_each_brand {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 578px !important;
  height: 200px !important;
  background-color: var(---almost_white) !important;
  padding: 8px 22px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  text-transform: none !important;
}

/*  */

.alice_each_item {
  display: flex;
  flex-direction: column;
  color: var(---black) !important;
  padding: 10px;
}

/* category */

.sc_cat {
  display: flex !important;
  flex-direction: column !important;
  width: 200px !important;
  height: 66px !important;
  margin-right: 20px !important;
  margin-left: 2px !important;
}

.sc_cat_each {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  text-align: left !important;
  width: 200px !important;
  height: 66px !important;
  background-color: var(---almost_white) !important;
  padding: 8px 22px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  text-transform: none !important;
}

.sc_cat_each_new {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  /* height: auto !important; */
  /* background-color: var(---pure); */
  /* background-color: var(---almost_white) !important; */
  color: var(---text) !important;
  text-transform: none !important;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important; */
}

.sc_cat_each_skel {
  width: 200px !important;
  height: 66px !important;
  margin-right: 20px !important;
  margin-left: 2px !important;
}

.sc_cat_icon {
  width: 50px !important;
  height: 50px !important;
}

.sc_cat_icon_brand {
  width: 150px !important;
  height: 150px !important;
}

.sc_cat_left {
  width: 40px !important;
  height: 40px !important;
  /* border-radius: 50% !important; */
  /* padding: 0px !important; */
}

/* past orders */

.sc_past_orders {
  display: flex;
  width: 320px;
  flex-direction: column;
  color: var(---black) !important;
  padding: 16px 10px;
  background: var(---white);
  border: 1px solid var(---gray);
  border-radius: 12px;
  margin-right: 10px;
  align-items: flex-start;
  cursor: pointer;
}

.sc_past_orders_skel {
  width: 320px;
  height: 172px !important;
  border-radius: 12px;
  margin-right: 10px;
}

.sc_po_avatar {
  width: 60px !important;
  height: 60px !important;
  border-radius: 5px !important;
  margin-right: 8px !important;
}

/* offers */

.sc_offer {
  display: flex;
  width: 380px;
  height: 214px;
  flex-direction: column;
  color: var(---black) !important;
  background: var(---white);
  border-radius: 12px;
  margin-right: 10px;
  align-items: flex-start;
}

.sc_offer_all {
  height: 100%;
}

.sc_offer_skel {
  width: 380px !important;
  height: 200px !important;
  border-radius: 12px !important;
  margin-right: 10px !important;
}

.sc_offer_item {
  width: 100%;
  height: 100%;
}

/* combo */

.sc_combo_div_slick {
  display: flex;
  flex-direction: column;
  width: 285px;
  height: 100% !important;
  margin-right: 15px;
  padding: 0.5em;
}

.sc_combo_div_skel {
  width: 285px !important;
  height: 335px !important;
  margin-right: 15px;
}

.sc_combo_div_all {
  display: flex;
  flex-direction: column;
  /* width: 285px; */
  height: 100% !important;
  margin-right: 15px;
}

.sc_cm_item_div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sc_cm_item {
  background: var(---secondary);
  border-radius: 5px;
  padding: 4px;
  margin-right: 8px;
  line-height: 35px;
  font-size: var(---font_size_14) !important;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  display: flex !important;
  height: auto !important;
}

/* popular items */

.hp_each_popular {
  border: 1px solid var(---black);
}

.hp_each_popular_skel {
  height: 345px !important;
}

.hp_each_popular_image {
  width: 282px !important;
  height: 219px !important;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.hp_each_popular_image_combo {
  width: 100% !important;
  height: 200px !important;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}

.hp_each_popular_image_combo_search {
  width: 100% !important;
  height: 200px !important;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}

.hp_each_popular_image_combo_search {
  width: 100% !important;
  height: 100%;
}

.showMoreSpace {
  margin-top: 35px;
}

/* modal */

.hp_modal {
  background-color: var(---white);
}

.hp_modal_body {
  position: relative;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  scrollbar-width: thin;
  max-height: calc(80vh - 80px);
  margin-bottom: 80px;
}

.hp_modal_body_x {
  position: absolute !important;
  /* width: 26px !important; */
  /* height: 26px !important; */
  right: 15px !important;
  top: 15px !important;
  background-color: #fff !important;
  padding: 2px !important;
}

.ant-modal-body {
  padding: 0;
}

.hp_modal_image {
  display: flex;
  width: 100%;
  transition: all linear 0.5s;
}

.cart_footer {
  position: fixed;
  height: 80px;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: var(---white);
}

.spicy_slider_div {
  display: flex;
  flex-direction: column;
  width: 290px;
  margin: 0 auto;
}

.spicy_slider_div_label {
  display: flex;
  width: 380px;
  margin: 0 auto;
}

.spiceLevel_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(---primary);
  text-transform: uppercase;
  margin: 20px auto;
  margin-top: 0px;
}

.css-1o5sm2b-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-1o5sm2b-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: var(---primary) !important;
}

/* media query */

/* mobile */

@media only screen and (max-width: 600px) {
  .sc_cm_item {
    font-size: var(---font_size_12) !important;
    line-height: var(---line_height_36) !important;
  }

  /* homepage */
  .hp_each_popular_image {
    width: 100% !important;
    height: 120px !important;
    margin-bottom: 0.6rem;
  }

  .hp_each_popular_skel {
    height: 100px !important;
  }

  /* category */
  .sc_cat {
    width: fit-content !important;
    height: 70px !important;
    margin-right: 6px !important;
  }

  .sc_cat_each {
    flex-direction: column-reverse !important;
    width: fit-content !important;
    height: 70px !important;
    padding: 0 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    background-color: transparent !important;
  }

  .spicy_slider_div {
    width: 100%;
  }

  .hp_each_popular_image_combo_search {
    height: 35vw !important;
  }

  .sc_cat_icon_brand {
    width: 74px !important;
    height: 74px !important;
  }

  .sc_brand_sel {
    display: flex !important;
    flex-direction: column !important;
    width: 280px !important;
    height: 118px !important;
    margin-right: 20px !important;
    margin-left: 2px !important;
  }

  .sc_cat_each_brand {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 280px !important;
    height: 118px !important;
    background-color: var(---almost_white) !important;
    padding: 0 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: var(---text) !important;
    text-transform: none !important;
    padding-left: 10px !important;
  }

  /* offer */
  .sc_offer {
    width: 89vw;
    height: 50vw;
  }
}

/* Portrait tablet 600-770*/

@media only screen and (min-device-width: 600px) and (max-device-width: 769px) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .hp_each_popular_image {
    width: 100% !important;
    height: 122px !important;
    margin: 0 !important;
  }

  .hp_each_popular_image_combo {
    height: 180px !important;
  }

  .hp_each_popular_image_combo_search {
    height: 180px !important;
  }
}

/* Portrait tablet*/

@media only screen and (min-device-width: 770px) and (max-device-width: 1024px) and (orientation: portrait) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .hp_each_popular_image {
    width: 100% !important;
    margin: 0 !important;
  }

  .hp_each_popular_image_combo {
    height: 200px !important;
  }

  .hp_each_popular_image_combo_search {
    height: 200px !important;
  }
}

/* Portrait tablet*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {

}
