@import "../fonts.css";

.kds_history_main {
  display: flex;
  width: 1180px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  justify-content: center;
  align-items: center;
}

.testOk {
  height: 100vh !important;
  background-color: var(---almost_white) !important;
}

.history_title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(---text);
}

.kds_order_each {
  background: var(---white);
  padding: 16px;
  border: 1px solid var(---gray);
  border-radius: 12px;
}

.kds_oe_title_div {
  display: flex;
  justify-content: space-between;
}

.ko_modal_top {
  display: flex;
  justify-content: space-between;
}

.ko_modal_left_title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(---dark_gray);
}

.ko_mt_track {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(---text);
}
