@import "../fonts.css";

.footerMain {
  display: flex;
  width: 100%;
  padding: 35px;
  background-color: var(---almost_white);
  color: var(---text) !important;
  margin-top: auto;
  font-family: "Poppins" !important;
  font-style: normal !important;
}

.footerMain_body {
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  width: 1180px;
}

.footer_each {
  display: flex;
  flex-direction: column;
  color: var(---text) !important;
  padding-left: 0 !important;
}

.footer_each_first {
  margin-left: auto;
}

.footer_each_title {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  text-transform: none !important;
  font-family: "Poppins" !important;
  justify-content: flex-start !important;
}

.footer_each_title_btn {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  text-transform: none !important;
  justify-content: flex-start !important;
}

/* privacy policy */

.fcon_each_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(---text);
  margin-top: 20px;
}

.fcon_each_title_sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.fcon_each_title_body {
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  text-justify: inter-word;
}

/* help and support */

.helpCurrentTabSel {
  min-width: fit-content !important;
  background: var(---secondary) !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---primary) !important;
  justify-content: left !important;
  padding: 12px 24px !important;
}

.helpCurrentTab {
  min-width: fit-content !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(---text) !important;
  justify-content: left !important;
  padding: 12px 24px !important;
}

/* who we are */
.whowearebg {
  background-image: url("https://novafoods1.s3.ap-south-1.amazonaws.com/general/whoweare.png");
  height: 100vh;
  width: 570px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*  media query */

/* mobile */

@media only screen and (max-width: 600px) {
  .footerMain_body {
    flex-direction: column;
  }

  .footer_each_first {
    margin-left: 0;
  }

  .footer_each {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1199px) {
  .footerMain_body {
    flex-direction: column;
  }
}
