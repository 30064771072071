@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import "../fonts.css";

.admin_auth {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.admin_auth_left {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.admin_auth_right {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.admin_auth_img {
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
}

.admin_auth_title {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: var(---primary);
}

.admin_input_div {
  display: flex;
  flex-direction: column;
  width: 410px;
}

.admin_input {
  width: 100%;
  outline: none;
  background: var(---white);
  border: 1px solid var(---dark_gray);
  border-radius: 12px;
  height: 40px;
  padding: 0 8px;
}

.ad_login_forgot_btn {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  color: var(---link) !important;
  border-radius: 12px !important;
  text-transform: none !important;
  height: 48px !important;
}

/* fb login */

.fb_sign_btn {
  width: 100% !important;
  background: var(---white) !important;
  border: 1px solid var(---gray) !important;
  border-radius: 12px !important;
  padding: 10px 8px !important;
  color: var(---text) !important;
  text-transform: none !important;
  /* justify-content: flex-start !important; */
}

/* google login */
.nsm7Bb-HzV7m-LgbsSe {
  color: var(---text) !important;
  font-weight: 600px !important;
  font-family: "Poppins" !important;
}

.book_test {
  font-family: "Yanone Kaffeesatz";
  font-style: normal;
  font-weight: 700;
  font-size: 170px;
  line-height: 140px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(---white);
}

/* .signInDiv {
    width: 700px !important;
    height: 60px !important;
} */

/* reset */

.admin_reset {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(---secondary);
  justify-content: center;
  align-items: center;
}

.enter_your_email {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(---primary);
}

.admin_reset_child_send {
  display: flex;
  width: 400px;
  flex-direction: column;
  padding: 30px;
  background-color: var(---white);
  border-radius: 12px;
}

.admin_reset_child_sent {
  display: flex;
  flex-direction: column;
  padding: 50px 120px;
  background-color: var(---white);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
}

.admin_reset_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(---text);
}

.admin_reset_email {
  font-weight: 600;
  color: var(---primary);
}

.admin_reset_btn {
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: var(---text) !important;
}

.admin_reset_btn_resend {
  margin-left: 3px;
  font-weight: 600 !important;
  color: var(---link) !important;
}

/*  media query */

/* mobile */

@media only screen and (max-width: 600px) {
  .admin_auth {
    height: auto;
    overflow-y: auto;
  }

  .admin_auth_left {
    width: 0%;
  }

  .admin_auth_right {
    width: 100%;
  }

  .admin_input_div {
    width: 95%;
  }

  /* reset */

  .admin_reset_child_send {
    width: 90vw;
    padding: 15px 25px;
  }

  .admin_reset_child_sent {
    padding: 15px 25px;
  }

  .admin_reset_title {
    font-size: 18px;
    line-height: 25px;
  }
}

/* Portrait tablet 600-770*/

@media only screen and (min-device-width: 600px) and (max-device-width: 769px) and (max-aspect-ratio: 13/9) and (-webkit-min-device-pixel-ratio: 1) {
  .admin_auth {
    height: auto;
    overflow-y: auto;
  }

  .admin_auth_left {
    width: 0%;
  }

  .admin_auth_right {
    width: 100%;
  }

  .admin_input_div {
    width: 95%;
  }

  /* reset */

  .admin_reset_child_send {
    padding: 15px 25px;
  }

  .admin_reset_child_sent {
    padding: 15px 25px;
  }

  .admin_reset_title {
    font-size: 18px;
    line-height: 25px;
  }
}
